import { CircularProgress } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Collapse from "@material-ui/core/Collapse";
import { green, grey, purple, red } from "@material-ui/core/colors";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Slide from "@material-ui/core/Slide";
import { createStyles, makeStyles, useTheme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { TransitionProps } from "@material-ui/core/transitions";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import Alert from "@material-ui/lab/Alert";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import { useErrorHandler } from "react-error-boundary";
import { getDivisionList, setDivision } from "../../api/DivisionApi";
import { CompanyContext } from "../../stores/Companies/Context";
import { DivisionContext } from "../../stores/Divisions/Context";
import { DivisionTypes } from "../../stores/Divisions/Reducers";
import { UserContext } from "../../stores/Users/Context";
import { Divisions } from "../../types/Divisions";
import "./DivisionNew.css";

const Transition = React.forwardRef(function Transition(
   props: TransitionProps & { children?: React.ReactElement<any, any> },
   ref: React.Ref<unknown>,
) {
   return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(() =>
   createStyles({
      label: {
         flexDirection: "column",
         fontSize: "11px",
      },
      buttonProgress: {
         color: green[500],
      },
      checkIcon: {
         color: red[500],
      },
      readOnlyButton: {
         color: "white",
         backgroundColor: grey[900],
         "&:hover": {
            backgroundColor: grey[800],
         },
      },
      adminButton: {
         color: "white",
         backgroundColor: green[300],
         "&:hover": {
            backgroundColor: green[400],
         },
      },
      superAdminButton: {
         color: "white",
         backgroundColor: purple[200],
         "&:hover": {
            backgroundColor: purple[300],
         },
      },
   }),
);

export const DivisionNew: React.FC<any> = (props: {
   open: boolean;
   handleClose: () => void;
   openAlertMessage: (alertMessage: string, alertType: "error" | "info" | "success" | "warning") => Promise<void>;
}): JSX.Element => {
   const classes = useStyles();
   const [alertMessage, setAlertMessage] = useState<string>("");
   const [alertType, setAlertType] = useState<"error" | "info" | "success" | "warning">("warning");
   const [divisionName, setDivisionName] = useState<string>("");
   const [divisionCode, setDivisionCode] = useState<string>("");
   const [emailTemplate, setEmailTemplate] = useState<string>("");
   const [company, setCompany] = useState<string>("");
   const [buttonClicked, setButtonClicked] = useState<boolean>(false);

   const theme = useTheme();
   const mobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
   const handleError = useErrorHandler();

   const { state: companyState } = useContext(CompanyContext);
   const { state: divisionState, dispatch: divisionDispatch } = useContext(DivisionContext);
   const { state: userState } = useContext(UserContext);

   async function addDivision() {
      try {
         if (!divisionName) {
            openAlertMessage("You must enter a division name.", "warning");
            return;
         }

         if (!divisionCode) {
            openAlertMessage("You must enter a division code.", "warning");
            return;
         }

         if (!emailTemplate) {
            openAlertMessage("You must select an email template.", "warning");
            return;
         }

         if (!company) {
            openAlertMessage("You must select a company.", "warning");
            return;
         }

         if (userState.CurrentUser.security.toUpperCase() !== "SUPER ADMIN") {
            openAlertMessage("Access to add new division is denied!", "warning");
            return;
         }

         const divisionCheck = _.filter(divisionState.Divisions, (x) => x.code === divisionCode);

         if (divisionCheck.length > 0) {
            openAlertMessage("Division code already exists!", "warning");
            return;
         }

         setButtonClicked(true);

         const newDivision: Divisions = {
            _id: "",
            company_id: company,
            code: divisionCode,
            name: divisionName,
            managers: [],
            active: true,
            prefix: divisionCode,
            counter: 1,
            addDate: new Date(),
            deleteDate: null,
            invitationBody: "",
            invitationTopNote: "",
            emailTemplate: emailTemplate,
            docuSignTemplateId: "",
            receiptFirstParagraph: "",
            receiptSecondParagraph: "",
            deniedFirstParagraph: "",
            deniedSecondParagraph: "",
            approvedFirstParagraph: "",
            approvedSecondParagraph: "",
            approvedBoldParagraph: "",
            contactApprovedBody: "",
            contactDeniedBody: "",
            duplicationNotification: "",
            contactDuplicationNotification: "",
            denialCodes: [],
            contacts: [],
         };

         const responseData = await setDivision(newDivision);

         if (responseData.success) {
            const responseDataDivision = await getDivisionList();

            if (responseDataDivision.success && responseDataDivision.data) {
               const divisions: Divisions[] = responseDataDivision.data;

               divisionDispatch({
                  type: DivisionTypes.Create,
                  payload: { Divisions: divisions },
               });
            } else {
               console.error("Unable to refresh the division state.");
            }

            props.openAlertMessage(
               "Successfully saved company. Please enter the DocuSign Template Id and all Email Verbiage in Division Details.",
               "success",
            );

            setButtonClicked(false);
            props.handleClose();
         } else {
            openAlertMessage(responseData.message, "warning");
            setButtonClicked(false);
            return;
         }
      } catch (err) {
         handleError(err);
      }
   }

   //Loading Use Effect.
   useEffect(() => {
      setAlertMessage("");
      setAlertType("warning");
      setDivisionName("");
      setDivisionCode("");
      setEmailTemplate("");
      setCompany("");
      setButtonClicked(false);
   }, [props.open]);

   async function onChange(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, value: "name" | "code") {
      try {
         if (value === "name") {
            setDivisionName(event.target.value ? event.target.value : "");
            return;
         } else {
            setDivisionCode(event.target.value ? event.target.value.toUpperCase() : "");
            return;
         }
      } catch (err) {
         handleError(err);
      }
   }

   async function onSelectChange(
      event: React.ChangeEvent<{
         name?: string | undefined;
         value: unknown;
      }>,
   ) {
      try {
         setEmailTemplate(event.target.value ? (event.target.value as string) : "");
      } catch (err) {
         handleError(err);
      }
   }

   async function onSelectChangeCompany(
      event: React.ChangeEvent<{
         name?: string | undefined;
         value: unknown;
      }>,
   ) {
      try {
         setCompany(event.target.value ? (event.target.value as string) : "");
      } catch (err) {
         handleError(err);
      }
   }

   async function openAlertMessage(alertMessage: string, alertType: "error" | "info" | "success" | "warning") {
      setAlertMessage(alertMessage);
      setAlertType(alertType);

      if (alertType === "success" || alertType === "warning") {
         setTimeout(function () {
            setAlertMessage("");
         }, 3000);
      }
   }

   return (
      <Dialog open={props.open || false} TransitionComponent={Transition} keepMounted fullScreen={mobileScreen}>
         <DialogTitle className="DivisionNew-title">
            <Grid container direction={"row"} item xs={12} alignItems={"center"} justify={"center"}>
               <PersonAddIcon style={{ marginRight: "1rem" }} />
               {"Add New Division"}
            </Grid>
         </DialogTitle>
         <Collapse in={alertMessage ? true : false}>
            <Alert
               variant="filled"
               severity={alertType}
               action={
                  <IconButton
                     aria-label="close"
                     color="inherit"
                     size="small"
                     onClick={() => {
                        setAlertMessage("");
                     }}
                  >
                     <CloseIcon />
                  </IconButton>
               }
            >
               {alertMessage}
            </Alert>
         </Collapse>
         <DialogContent>
            <Grid
               container
               direction={"row"}
               item
               xs={12}
               style={{ placeContent: "space-evenly", margin: "auto", marginRight: "1.5rem" }}
            >
               <TextField
                  id="ci-divisionName"
                  value={divisionName}
                  onChange={(event) => onChange(event, "name")}
                  label="Division Name"
                  variant="outlined"
                  fullWidth
                  style={{ width: mobileScreen ? "100%" : "30rem" }}
               />
            </Grid>
            <Grid container direction={"row"} item xs={12} style={{ placeContent: "space-evenly", margin: "auto" }}>
               <TextField
                  id="ci-divisionCode"
                  value={divisionCode}
                  onChange={(event) => onChange(event, "code")}
                  label="Division Code"
                  variant="outlined"
                  fullWidth
                  style={{ width: mobileScreen ? "100%" : "30rem" }}
               />
            </Grid>
            <Grid container direction={"row"} item xs={12} style={{ placeContent: "space-evenly", margin: "auto" }}>
               <FormControl variant="outlined" style={{ width: mobileScreen ? "100%" : "30rem", textAlign: "left" }}>
                  <InputLabel>Email Template</InputLabel>
                  <Select
                     id="select-emailtemplate"
                     defaultValue=""
                     label="Email Template"
                     value={emailTemplate}
                     onChange={(event) => onSelectChange(event)}
                  >
                     <MenuItem value={"CPG"}>{"CPG"}</MenuItem>
                     <MenuItem value={"IDS"}>{"IDS"}</MenuItem>
                     <MenuItem value={"MIDDLEBAY"}>{"Middlebay"}</MenuItem>
                     <MenuItem value={"BRISTOL"}>{"Bristol"}</MenuItem>
                     <MenuItem value={"WDS"}>{"World Distribution Services"}</MenuItem>
                     <MenuItem value={"UWL"}>{"UWL, Inc."}</MenuItem>
                  </Select>
               </FormControl>
            </Grid>
            <Grid container direction={"row"} item xs={12} style={{ placeContent: "space-evenly", margin: "auto" }}>
               <FormControl variant="outlined" style={{ width: mobileScreen ? "100%" : "30rem", textAlign: "left" }}>
                  <InputLabel id="demo-simple-select-filled-label">Company</InputLabel>
                  <Select
                     id="select-companies"
                     defaultValue=""
                     label="Company"
                     onChange={(event) => onSelectChangeCompany(event)}
                     value={company}
                  >
                     {companyState.Companies.map((company) => {
                        return (
                           <MenuItem key={company._id} value={company._id}>
                              {company.code + " | " + company.name}
                           </MenuItem>
                        );
                     })}
                  </Select>
               </FormControl>
            </Grid>
         </DialogContent>
         <DialogActions>
            <ButtonGroup>
               <Button
                  onClick={props.handleClose}
                  variant="contained"
                  classes={{
                     label: classes.label,
                  }}
               >
                  <CloseIcon />
                  Close
               </Button>
               <Button
                  onClick={addDivision}
                  variant="contained"
                  color="primary"
                  classes={{
                     label: classes.label,
                  }}
                  disabled={buttonClicked}
               >
                  {buttonClicked ? <CircularProgress size={20} className={classes.buttonProgress} /> : <AddIcon />}
                  Add Division
               </Button>
            </ButtonGroup>
         </DialogActions>
      </Dialog>
   );
};

DivisionNew.propTypes = {
   handleClose: PropTypes.func.isRequired,
   open: PropTypes.bool.isRequired,
   openAlertMessage: PropTypes.func.isRequired,
};

export default DivisionNew;
