import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { createStyles, makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import AssignmentIcon from "@material-ui/icons/Assignment";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import DeviceHubIcon from "@material-ui/icons/DeviceHub";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import clsx from "clsx";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { version } from "../../../package.json";
import { DrawerContext } from "../../stores/Drawer/Context";
import { Types } from "../../stores/Drawer/Reducers";
import { UserContext } from "../../stores/Users/Context";
import * as CookieTools from "../../utilities/CookieTools";

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
   createStyles({
      root: {
         display: "flex",
         fontSize: "20px",
         color: "#002D72",
         height: "100%",
         zIndex: 3,
         backgroundColor: "#FAFBFC",
         minWidth: theme.spacing(7),
      },
      drawer: {
         width: drawerWidth,
         flexShrink: 0,
         whiteSpace: "nowrap",
         backgroundColor: "#FAFBFC",
      },
      drawerOpen: {
         width: drawerWidth,
         transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
         }),
         overflowX: "hidden",
         backgroundColor: "#FAFBFC",
      },
      versionOpen: {
         backgroundColor: "#FAFBFC",
         color: "#002D72",
         textAlign: "center",
         paddingTop: "48vh",
         transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
         }),
      },
      drawerClose: {
         transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
         }),
         overflowX: "hidden",
         minWidth: theme.spacing(7),
         width: theme.spacing(5),
         [theme.breakpoints.up("sm")]: {
            width: theme.spacing(7),
         },
         backgroundColor: "#FAFBFC",
      },
      toolbar: {
         display: "flex",
         alignItems: "center",
         justifyContent: "flex-end",
         padding: theme.spacing(0, 1),
         height: "78px",
         backgroundColor: "#FAFBFC",
      },
      listItemText: {
         fontSize: "20px",
         color: "#002D72",
      },
      iconButton: {
         backgroundColor: "#FAFBFC",
      },
      divider: {
         backgroundColor: "#778BA3",
         height: "3px",
         width: "90%",
         marginLeft: "5%",
         marginRight: "5%",
      },
   }),
);

export const Sidebar: React.FC<any> = (): JSX.Element => {
   const classes = useStyles();
   const history = useHistory();
   const [open, setOpen] = useState(false);
   const [superAdmin, setSuperAdmin] = useState(false);
   const { state: drawerState, dispatch: drawerDispatch } = useContext(DrawerContext);
   const { state: userState } = useContext(UserContext);
   const theme = useTheme();
   const mobileScreen = useMediaQuery(theme.breakpoints.down("sm"));

   const handleDrawerOpen = () => {
      setOpen(true);
      CookieTools.setCookie("ca-drawer-open", "true");
      drawerDispatch({
         type: Types.Open,
         payload: { DrawerOpen: true },
      });
   };

   const handleDrawerClose = () => {
      setOpen(false);
      CookieTools.setCookie("ca-drawer-open", "false");
      drawerDispatch({
         type: Types.Close,
         payload: { DrawerOpen: false },
      });
   };

   function handleClick(event: React.FormEvent<HTMLDivElement>, selectedRoute: string) {
      if (selectedRoute) {
         history.push("/" + selectedRoute);
      }
   }

   useEffect(() => {
      if (CookieTools.checkCookie("ca-drawer-open")) {
         if (CookieTools.getCookie("ca-drawer-open") === "true") {
            setOpen(true);
            drawerDispatch({
               type: Types.Open,
               payload: { DrawerOpen: true },
            });
         }
      }
   }, []);

   //Loading Use Effect
   useEffect(() => {
      if (userState.CurrentUser._id) {
         if (userState.CurrentUser.security.toUpperCase() === "SUPER ADMIN") {
            setSuperAdmin(true);
         }
      }
   }, [userState]);

   return (
      <div className={classes.root}>
         <Drawer
            variant="permanent"
            className={clsx(classes.drawer, {
               [classes.drawerOpen]: open,
               [classes.drawerClose]: !open,
            })}
            classes={{
               paper: clsx({
                  [classes.drawerOpen]: open,
                  [classes.drawerClose]: !open,
               }),
            }}
         >
            <div className={classes.toolbar}>
               <div style={{ display: !mobileScreen ? "block" : "none" }}>
                  <div style={{ display: !open ? "block" : "none" }}>
                     <Tooltip title="Open Menu" arrow>
                        <IconButton onClick={handleDrawerOpen} hidden={!open}>
                           <ChevronRightIcon color="primary" />
                        </IconButton>
                     </Tooltip>
                  </div>
               </div>
               <div style={{ display: !mobileScreen ? "block" : "none" }}>
                  <div style={{ display: open ? "block" : "none" }}>
                     <b className={classes.listItemText}>{`Credit Application `}</b>
                     <Tooltip title="Collapse Menu" arrow>
                        <IconButton onClick={handleDrawerClose} hidden={open}>
                           <ChevronLeftIcon color="primary" />
                        </IconButton>
                     </Tooltip>
                  </div>
               </div>
            </div>
            <Divider className={classes.divider} />
            <List>
               <ListItem
                  button
                  key={"applications"}
                  onClick={(event) => handleClick(event, "applications")}
                  selected={window.location.href.includes("/applications") ? true : false}
               >
                  <ListItemIcon>
                     {drawerState.DrawerOpen ? (
                        <AssignmentIcon color="primary" />
                     ) : (
                        <Tooltip title="Applications" arrow>
                           <AssignmentIcon color="primary" />
                        </Tooltip>
                     )}
                  </ListItemIcon>
                  <ListItemText primary={"Applications"} classes={{ primary: classes.listItemText }} />
               </ListItem>
               <div style={{ display: superAdmin ? "block" : "none" }}>
                  <ListItem
                     button
                     key={"companies"}
                     onClick={(event) => handleClick(event, "companies")}
                     selected={window.location.href.includes("/companies") ? true : false}
                  >
                     <ListItemIcon>
                        {drawerState.DrawerOpen ? (
                           <BusinessCenterIcon color="primary" />
                        ) : (
                           <Tooltip title="Companies" arrow>
                              <BusinessCenterIcon color="primary" />
                           </Tooltip>
                        )}
                     </ListItemIcon>
                     <ListItemText primary={"Companies"} classes={{ primary: classes.listItemText }} />
                  </ListItem>
                  <ListItem
                     button
                     key={"divisions"}
                     onClick={(event) => handleClick(event, "divisions")}
                     selected={window.location.href.includes("/divisions") ? true : false}
                  >
                     <ListItemIcon>
                        {drawerState.DrawerOpen ? (
                           <DeviceHubIcon color="primary" />
                        ) : (
                           <Tooltip title="Divisions" arrow>
                              <DeviceHubIcon color="primary" />
                           </Tooltip>
                        )}
                     </ListItemIcon>
                     <ListItemText primary={"Divisions"} classes={{ primary: classes.listItemText }} />
                  </ListItem>
               </div>
               <ListItem
                  button
                  key={"users"}
                  onClick={(event) => handleClick(event, "users")}
                  selected={window.location.href.includes("/users") ? true : false}
               >
                  <ListItemIcon>
                     {drawerState.DrawerOpen ? (
                        <SupervisedUserCircleIcon color="primary" />
                     ) : (
                        <Tooltip title="Users" arrow>
                           <SupervisedUserCircleIcon color="primary" />
                        </Tooltip>
                     )}
                  </ListItemIcon>
                  <ListItemText primary={"Users"} classes={{ primary: classes.listItemText }} />
               </ListItem>
            </List>
            <div className={classes.versionOpen}>
               <Divider className={classes.divider} />
               <div style={{ paddingTop: "1rem", display: open ? "block" : "none" }}>Version: {version}</div>
            </div>
         </Drawer>
      </div>
   );
};

export default Sidebar;
