/* eslint-disable prettier/prettier */
import {
   Breadcrumbs,
   Button,
   ButtonGroup,
   Card,
   CardContent,
   CardHeader,
   Chip,
   CircularProgress,
   Collapse,
   Divider,
   FormControl,
   IconButton,
   Link,
   MenuItem,
   Paper,
   Select,
   Table,
   TableBody,
   TableCell,
   TableContainer,
   TableHead,
   TableRow,
   TextField,
   Tooltip,
   Typography,
} from "@material-ui/core";
import Grid, { GridSize } from "@material-ui/core/Grid";
import { green, grey, red, yellow } from "@material-ui/core/colors";
import { Theme, createStyles, makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import DescriptionIcon from "@material-ui/icons/Description";
import DownloadIcon from "@material-ui/icons/GetApp";
import LinkIcon from "@material-ui/icons/Link";
import MailIcon from "@material-ui/icons/Mail";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NoteIcon from "@material-ui/icons/Note";
import PrintIcon from "@material-ui/icons/Print";
import PublishIcon from "@material-ui/icons/Publish";
import SaveIcon from "@material-ui/icons/Save";
import ThumbDownIcon from "@material-ui/icons/ThumbDown";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import Alert from "@material-ui/lab/Alert";
import { saveAs } from "file-saver";
import _ from "lodash";
import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import { useErrorHandler } from "react-error-boundary";
import { RouteComponentProps, useHistory } from "react-router-dom";
import {
   deleteApplication as deleteApplicationApi,
   getApplicationById,
   getApplicationPdf,
   getDocuSignPdf,
   getTradeReferencesLastModifiedDate,
   getTradeReferencesPdf,
   sendInvite as sendInviteApi,
   // PRETTIER ISSUE
   // eslint-disable-next-line prettier/prettier
   setApplication,
   setTradeReferencesDocument,
} from "../../api/ApplicationApi";
import ApprovalDialog from "../../components/ApprovalDialog/ApprovalDialog";
import DeleteDialog from "../../components/DeleteDialog/DeleteDialog";
import DenialDialog from "../../components/DenialDialog/DenialDialog";
import NotesDialog from "../../components/NotesDialog/NotesDialog";
import SaveDialog from "../../components/SaveDialog/SaveDialog";
import UploadDialog from "../../components/UploadDialog/UploadDialog";
import { CompanyContext } from "../../stores/Companies/Context";
import { DivisionContext } from "../../stores/Divisions/Context";
import { DrawerContext } from "../../stores/Drawer/Context";
import { UserContext } from "../../stores/Users/Context";
import CheckAnimation from "../../styles/CheckAnimation";
import { Applications } from "../../types/Applications";
import { Contacts } from "../../types/Contacts";
import { DenialCodes } from "../../types/DenialCodes";
import { DUNSFormatter } from "../../utilities/DUNSFormatter";
import { DateFormatter } from "../../utilities/DateFormatter";
import { DateTimeFormatterShortYear } from "../../utilities/DateTimeFormatterShortYear";
import { PhoneFormatter } from "../../utilities/PhoneFormatter";
import { validateEmailAddress } from "../../utilities/validateEmailAddress";
import "./ApplicationDetails.css";

const useStyles = makeStyles((theme: Theme) =>
   createStyles({
      headerDrawerOpen: {
         marginLeft: 240,
      },
      headerDrawerClosed: {
         marginLeft: theme.spacing(7),
      },
      divider: {
         backgroundColor: "#778BA3",
         height: "3px",
         width: "100%",
      },
      activeChip: {
         backgroundColor: green[500],
         color: "white",
      },
      inactiveChip: {
         backgroundColor: red[500],
         color: "white",
      },
      label: {
         flexDirection: "column",
         fontSize: "11px",
      },
      labelMobile: {
         flexDirection: "column",
         fontSize: "7px",
      },
      buttonProgress: {
         color: green[500],
      },
      saveButton: {
         color: "white",
         backgroundColor: green[500],
         "&:hover": {
            backgroundColor: green[600],
         },
      },
      notesButton: {
         color: "white",
         backgroundColor: "#FCC201",
         "&:hover": {
            backgroundColor: yellow[700],
         },
      },
      docuSignButton: {
         color: "white",
         backgroundColor: "#555555",
         "&:hover": {
            backgroundColor: grey[800],
         },
      },
      chipContainer: {
         height: "100%",
         display: "flex",
         alignItems: "center",
         justifyContent: "left",
         marginLeft: "1.75rem",
      },
      chipGreen: {
         color: "white",
         backgroundColor: green[500],
      },
      chipRed: {
         color: "white",
         backgroundColor: red[500],
      },
   }),
);

export const ApplicationDetails: React.FC<any> = ({ match }: RouteComponentProps<{ id: string }>): JSX.Element => {
   const classes = useStyles();
   const history = useHistory();
   const theme = useTheme();
   const mobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
   const handleError = useErrorHandler();

   const { state: drawerState } = useContext(DrawerContext);
   const [selectedApplication, setSelectedApplication] = useState<Applications>({
      _id: "",
      applicationNumber: "",
      email: "",
      companyName: "",
      companyEstablishedDate: null,
      accountsPayableEmail: "",
      companyEmail: "",
      companyContact: "",
      companyWebsite: "",
      ctpatNumber: "",
      address: {
         _id: "",
         address1: "",
         address2: "",
         city: "",
         state: "",
         zip: "",
         country: "",
      },
      billingAddress: {
         _id: "",
         address1: "",
         address2: "",
         city: "",
         state: "",
         zip: "",
         email: "",
         phone: "",
         contact: "",
      },
      chb: {
         _id: "",
         uwlHandleChb: false,
         achImporter: false,
         companyName: "",
         contact: "",
         address: "",
         email: "",
         city: "",
         state: "",
         zip: "",
         phone: "",
      },
      companyPhone: "",
      status: "",
      amountReq: 0,
      numberEmployees: 0,
      annualRevenue: 0,
      method: {
         code: "",
         value: "",
      },
      ownership: "",
      documents: [],
      bankInfo: {
         duns: "",
         bankName: "",
         accountNumber: "",
         principalName: "",
         address1: "",
         address2: "",
         city: "",
         state: "",
         zip: "",
         contact: "",
         phone: "",
         country: "",
         federalTaxNumber: "",
         paymentMethod: "",
      },
      tradeRefInfo: [
         {
            _id: "",
            company: "",
            address: "",
            city: "",
            state: "",
            country: "",
            phone: "",
            email: "",
            contact: "",
         },
         {
            _id: "",
            company: "",
            address: "",
            city: "",
            state: "",
            country: "",
            phone: "",
            email: "",
            contact: "",
         },
         {
            _id: "",
            company: "",
            address: "",
            city: "",
            state: "",
            country: "",
            phone: "",
            email: "",
            contact: "",
         },
      ],
      tradeReferencesFileName: "",
      signature: "",
      signatureDate: null,
      signatureTitle: "",
      code: "",
      approvedBy_id: "",
      approvedAmount: 0,
      netTerms: "",
      company_id: "",
      division_id: "",
      isCOD: null,
      notes: "",
      companyCode: "",
      fedTax_id: "",
      referral: [],
      notifiedBy_id: "",
      notifiedDate: null,
      docuSignEnvelope_id: "",
      docuSignCompleted: null,
      approvedOrDeniedDate: null,
      contacts: [],
      applicationOpened: null,
      addDate: new Date(),
      deleteDate: null,
      tradeReferencesSkipped: false,
   });
   const [originalSelectedApplication, setOriginalSelectedApplication] = useState<Applications>({
      _id: "",
      applicationNumber: "",
      email: "",
      companyName: "",
      companyEstablishedDate: null,
      accountsPayableEmail: "",
      companyEmail: "",
      companyContact: "",
      companyWebsite: "",
      ctpatNumber: "",
      address: {
         _id: "",
         address1: "",
         address2: "",
         city: "",
         state: "",
         zip: "",
         country: "",
      },
      billingAddress: {
         _id: "",
         address1: "",
         address2: "",
         city: "",
         state: "",
         zip: "",
         email: "",
         phone: "",
         contact: "",
      },
      chb: {
         _id: "",
         uwlHandleChb: false,
         achImporter: false,
         companyName: "",
         contact: "",
         address: "",
         email: "",
         city: "",
         state: "",
         zip: "",
         phone: "",
      },
      companyPhone: "",
      status: "",
      amountReq: 0,
      numberEmployees: 0,
      annualRevenue: 0,
      method: {
         code: "",
         value: "",
      },
      ownership: "",
      documents: [],
      bankInfo: {
         duns: "",
         bankName: "",
         accountNumber: "",
         principalName: "",
         address1: "",
         address2: "",
         city: "",
         state: "",
         zip: "",
         contact: "",
         phone: "",
         country: "",
         federalTaxNumber: "",
         paymentMethod: "",
      },
      tradeRefInfo: [
         {
            _id: "",
            company: "",
            address: "",
            city: "",
            state: "",
            country: "",
            phone: "",
            email: "",
            contact: "",
         },
         {
            _id: "",
            company: "",
            address: "",
            city: "",
            state: "",
            country: "",
            phone: "",
            email: "",
            contact: "",
         },
         {
            _id: "",
            company: "",
            address: "",
            city: "",
            state: "",
            country: "",
            phone: "",
            email: "",
            contact: "",
         },
      ],
      tradeReferencesFileName: "",
      signature: "",
      signatureDate: null,
      signatureTitle: "",
      code: "",
      approvedBy_id: "",
      approvedAmount: 0,
      netTerms: "",
      company_id: "",
      division_id: "",
      isCOD: null,
      notes: "",
      companyCode: "",
      fedTax_id: "",
      referral: [],
      notifiedBy_id: "",
      notifiedDate: null,
      docuSignEnvelope_id: "",
      docuSignCompleted: null,
      approvedOrDeniedDate: null,
      contacts: [],
      applicationOpened: null,
      addDate: new Date(),
      deleteDate: null,
      tradeReferencesSkipped: false,
   });
   const [alertMessage, setAlertMessage] = useState<string>("");
   const [alertType, setAlertType] = useState<"error" | "info" | "success" | "warning">("warning");
   const { state: companyState } = useContext(CompanyContext);
   const { state: divisionState } = useContext(DivisionContext);
   const [buttonClicked, setButtonClicked] = useState<boolean>(false);
   const [resendClicked, setResendClicked] = useState<boolean>(false);
   const [uploadClicked, setUploadClicked] = useState<boolean>(false);
   const [loading, setLoading] = useState(true);
   const [notesModal, setNotesModal] = useState<boolean>(false);
   const [approvalModal, setApprovalModal] = useState<boolean>(false);
   const [denialModal, setDenialModal] = useState<boolean>(false);
   const [saveModal, setSaveModal] = useState<boolean>(false);
   const [emailing, setEmailing] = useState<boolean>(false);
   const [printing, setPrinting] = useState<boolean>(false);
   const [docuSign, setDocuSign] = useState<boolean>(false);
   const [deleting, setDeleting] = useState<boolean>(false);
   const [linkClicked, setLinkClicked] = useState<boolean>(false);
   const [deleteModal, setDeleteModal] = useState<boolean>(false);
   const [selectedDivision, setSelectedDivision] = useState("");
   const [changeEmailText, setChangeEmailText] = useState("");
   const [deleteText, setDeleteText] = useState<string>("");
   const [selectedApplicationId, setSelectedApplicationId] = useState<string>("");
   const [denialCodesList, setDenialCodesList] = useState<DenialCodes[]>([]);
   const [contactList, setContactList] = useState<Contacts[]>([]);
   const [fileUpload, setFileUpload] = useState<File | null>();
   const [fileUploadModal, setFileUploadModal] = useState<boolean>(false);
   const [fileDate, setFileDate] = useState<Date | null>(null);
   const [downloading, setDownloading] = useState<boolean>(false);

   const { state: userState } = useContext(UserContext);

   useEffect(() => {
      getTradeReferencesDate();
      getApplication();
   }, []);

   //Contact list Use Effect.
   useEffect(() => {
      if (selectedApplication._id && companyState.Companies.length > 0 && divisionState.Divisions.length > 0) {
         if (selectedApplication.division_id) {
            const divisionAssigned = _.filter(
               divisionState.Divisions,
               (x) => x._id.toString() === selectedApplication.division_id.toString(),
            );

            if (divisionAssigned.length > 0) {
               const contactAssigned = _.filter(divisionAssigned[0].contacts, (x) =>
                  selectedApplication.contacts.includes(
                     selectedApplication.contacts ? x._id : selectedApplication.contacts,
                  ),
               );
               setContactList(contactAssigned);
            }
         } else {
            const companyAssigned = _.filter(
               companyState.Companies,
               (x) => x._id.toString() === selectedApplication.company_id.toString(),
            );

            if (companyAssigned.length > 0) {
               const contactAssigned = _.filter(companyAssigned[0].contacts, (x) =>
                  selectedApplication.contacts.includes(
                     selectedApplication.contacts ? x._id : selectedApplication.contacts,
                  ),
               );
               setContactList(contactAssigned);
            }
         }
      }
   }, [selectedApplication, divisionState, companyState]);

   useEffect(() => {
      if (companyState.Companies.length > 0 && divisionState.Divisions.length > 0 && selectedApplication._id) {
         if (selectedApplication.division_id) {
            const divisionAssigned = _.filter(
               divisionState.Divisions,
               (x) => x._id === selectedApplication.division_id,
            );

            if (divisionAssigned.length > 0) {
               setSelectedDivision(divisionAssigned[0].name);
               setDenialCodesList(divisionAssigned[0].denialCodes);
            }
         } else {
            const companyAssigned = _.filter(companyState.Companies, (x) => x._id === selectedApplication.company_id);

            if (companyAssigned.length > 0) {
               setSelectedDivision(companyAssigned[0].name);
               setDenialCodesList(companyAssigned[0].denialCodes);
            }
         }

         setLoading(false);
      }
   }, [selectedApplication, companyState, divisionState]);

   async function breadcrumbOnClick(path: string) {
      if (path) {
         history.push(path);
      }
   }

   async function getApplication() {
      try {
         const responseData = await getApplicationById(match.params.id);

         if (responseData.success) {
            setSelectedApplication(responseData.data);
            setOriginalSelectedApplication(responseData.data);
         }
      } catch (err) {
         handleError(err);
      }
   }

   const detailsCard = (title: string, details: Array<Array<{ label: string; value: string }>>) => {
      const content = details.map((row) => {
         const gridSizeNumber = 7 - Number(row.length);
         const gridSize: GridSize = 0 < gridSizeNumber && gridSizeNumber < 13 ? (gridSizeNumber as GridSize) : "auto";

         const headers = row.map((field) => {
            return (
               <Grid item xs={gridSize} key={title + "-" + field.label}>
                  <Typography variant="h6" noWrap style={{ color: "#010440", fontWeight: 700 }}>
                     {field.label}
                  </Typography>
               </Grid>
            );
         });

         const values = row.map((field) => {
            return (
               <Grid item xs={gridSize} key={title + "-" + field.value}>
                  <Typography>{field.value}</Typography>
               </Grid>
            );
         });

         return (
            <div key={`${title}-${row[0].label}`}>
               <Grid
                  container
                  direction={"row"}
                  style={{
                     height: "30px",
                  }}
                  justify="space-between"
               >
                  {headers}
               </Grid>
               <Grid container direction={"row"} justify="space-between">
                  {values}
               </Grid>
            </div>
         );
      });

      return (
         <Card style={{ width: "100%", height: "inherit", marginTop: theme.spacing(5) }}>
            <CardHeader
               className={"ApplicationDetails-card-header1"}
               style={{ height: "4rem" }}
               title={<Typography variant="h5">{title}</Typography>}
            />
            <Divider className={classes.divider} />
            <CardContent style={{ height: "280px" }}>{content}</CardContent>
         </Card>
      );
   };

   async function onChange(
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      value: "companyCode" | "notes" | "approvedAmount" | "netTerms" | "companyCode" | "email",
   ) {
      try {
         if (value === "notes") {
            const applicationObj = { ...selectedApplication };
            applicationObj[value] = event.target.value;
            setSelectedApplication(applicationObj);
         } else if (value === "approvedAmount") {
            const applicationObj = { ...selectedApplication };
            const onlyNums = event.target.value.replace(/[^0-9]/g, "");
            applicationObj.approvedAmount = onlyNums ? +onlyNums : 0;
            setSelectedApplication(applicationObj);
         } else {
            const applicationObj = { ...selectedApplication };
            applicationObj[value] = event.target.value;
            setSelectedApplication(applicationObj);
         }
      } catch (err) {
         handleError(err);
      }
   }

   async function onChangeNotes(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, value: "notes") {
      try {
         if (value === "notes") {
            const applicationObj = { ...originalSelectedApplication };
            applicationObj[value] = event.target.value;
            setOriginalSelectedApplication(applicationObj);
         }
      } catch (err) {
         handleError(err);
      }
   }

   async function onSelectChange(
      event: React.ChangeEvent<{
         name?: string | undefined;
         value: unknown;
      }>,
   ) {
      try {
         const applicationObj = { ...selectedApplication };
         applicationObj.code = event.target.value ? (event.target.value as string) : "";
         setSelectedApplication(applicationObj);
      } catch (err) {
         handleError(err);
      }
   }

   async function openAlertMessage(alertMessage: string, alertType: "error" | "info" | "success" | "warning") {
      setAlertMessage(alertMessage);
      setAlertType(alertType);

      if (alertType === "success" || alertType === "warning") {
         setTimeout(function () {
            setAlertMessage("");
         }, 3000);
      }
   }

   async function saveApplication(resendApplication = false) {
      try {
         if (userState.CurrentUser.security.toUpperCase() === "READ ONLY") {
            openAlertMessage("Access to change the application is denied!", "warning");
            return;
         }

         if (selectedApplication.status.toLowerCase() === "sent") {
            const emailResults = await validateEmailAddress(selectedApplication.email);

            if (emailResults) {
               openAlertMessage(emailResults, "warning");
               return;
            }

            if (
               selectedApplication.email.toLowerCase() !== originalSelectedApplication.email.toLowerCase() &&
               saveModal === false
            ) {
               setChangeEmailText(
                  `The application was originally sent to ${originalSelectedApplication.email}. You are about to change it to ${selectedApplication.email}.`,
               );
               setSaveModal(true);
               return;
            }
         }

         if (resendApplication === true && saveModal === false) {
            setResendClicked(true);
         } else {
            setButtonClicked(true);
         }

         const applicationObj = { ...selectedApplication };

         if (applicationObj.status === "NEW") {
            applicationObj.status = "PENDING";
         }

         let responseData;

         if (resendApplication) {
            applicationObj.addDate = new Date();
            responseData = await sendInviteApi(applicationObj);
         } else {
            responseData = await setApplication(applicationObj, "");
         }

         if (responseData.success) {
            openAlertMessage("Successfully updated application.", "success");
            setButtonClicked(false);
            setResendClicked(false);
            setSaveModal(false);
            getApplication();
         } else {
            openAlertMessage(responseData.message, "warning");
            setButtonClicked(false);
            setResendClicked(false);
            setSaveModal(false);
            return;
         }
      } catch (err) {
         openAlertMessage("An error has occurred. Please reach out to administration for assistance.", "error");
         setButtonClicked(false);
         setResendClicked(false);
         setSaveModal(false);
      }
   }

   async function handleAppLinkClick() {
      try {
         if (userState.CurrentUser.security.toUpperCase() === "READ ONLY") {
            openAlertMessage("Access to copy the application link is denied!", "warning");
            return;
         }

         setLinkClicked(true);

         let theme = "";

         if (selectedApplication.division_id) {
            const divisionAssigned = _.filter(
               divisionState.Divisions,
               (x) => x._id.toString() === selectedApplication.division_id.toString(),
            );

            theme =
               divisionAssigned[0].code === "BRI"
                  ? "Bristol"
                  : divisionAssigned[0].code === "MBT"
                  ? "MiddleBay"
                  : "CPG";
         } else {
            const companyAssigned = _.filter(
               companyState.Companies,
               (x) => x._id.toString() === selectedApplication.company_id.toString(),
            );

            theme = companyAssigned[0].name;
         }

         navigator.clipboard.writeText(
            window.location.href.replace(`applications/details/${selectedApplication._id}`, "") +
               "Apply/" +
               theme +
               "/" +
               selectedApplication.email +
               "/" +
               selectedApplication._id,
         );

         setTimeout(function () {
            setLinkClicked(false);
         }, 1000);
      } catch (err) {
         openAlertMessage("Error copying application link. Please contact your administrator for assistance!", "error");
      }
   }

   async function handleEmailClick() {
      try {
         setEmailing(true);

         window.open(
            `mailto:?subject=${selectedApplication.companyName.replace(
               "&",
               "AND",
            )} Credit Request Needs Further Review&body=Application Details:%20${
               window.location.origin
            }/applications/details/${selectedApplication._id}`,
         );

         setEmailing(false);
      } catch (err) {
         openAlertMessage("Error loading PDF application. Please contact your administrator for assistance!", "error");
         setEmailing(false);
      }
   }

   async function handlePrintClick() {
      try {
         setPrinting(true);

         const responseData = await getApplicationPdf(selectedApplication._id);

         if (responseData.data) {
            responseData.data.arrayBuffer().then((res: BlobPart) => {
               const blob = new Blob([res], { type: "application/pdf" });
               window.open(URL.createObjectURL(blob), "PRINT", "height=500,width=700");
            });
         } else {
            openAlertMessage(
               "Error loading PDF application. Please contact your administrator for assistance!",
               "error",
            );
         }

         setPrinting(false);
      } catch (err) {
         openAlertMessage("Error loading PDF application. Please contact your administrator for assistance!", "error");
         setPrinting(false);
      }
   }

   async function handleDocuSignClick() {
      try {
         if (selectedApplication.status.toUpperCase() !== "APPROVED") {
            openAlertMessage("Application is not approved!", "warning");
            return;
         }

         if (!selectedApplication.docuSignEnvelope_id) {
            openAlertMessage("DocuSign PDF is unavailable for this application!", "warning");
            return;
         }

         if (userState.CurrentUser.security.toUpperCase() === "READ ONLY") {
            openAlertMessage("Access to view the DocuSign PDF is denied!", "warning");
            return;
         }

         setDocuSign(true);

         const responseData = await getDocuSignPdf(selectedApplication._id);

         if (responseData.data) {
            responseData.data.arrayBuffer().then((res: BlobPart) => {
               const blob = new Blob([res], { type: "application/pdf" });
               window.open(URL.createObjectURL(blob));
            });
         } else {
            openAlertMessage("Error loading DocuSign PDF. Please contact your administrator for assistance!", "error");
         }

         setDocuSign(false);
      } catch (err) {
         openAlertMessage("Error loading DocuSign PDF. Please contact your administrator for assistance!", "error");
      }
   }

   async function handleDeleteClick() {
      try {
         if (userState.CurrentUser.security.toUpperCase() === "READ ONLY") {
            openAlertMessage("Access to resend an Application invite is denied!", "warning");
            return;
         }

         setSelectedApplicationId(selectedApplication._id);

         setDeleteText(
            `You are about to delete the application for ${
               selectedApplication.companyName ? selectedApplication.companyName : selectedApplication.email
            }`,
         );

         setDeleteModal(true);
      } catch (err) {
         handleError(err);
      }
   }

   async function deleteApplication() {
      try {
         if (userState.CurrentUser.security.toUpperCase() === "READ ONLY") {
            openAlertMessage("Access to resend an Application invite is denied!", "warning");
            return;
         }

         const responseData = await deleteApplicationApi(selectedApplicationId);
         setDeleteModal(false);

         if (responseData.success) {
            openAlertMessage("Application invite has been deleted", "success");
            setLoading(true);
            window.location.replace("/");
         } else {
            openAlertMessage("Error dleteing application. Please contact your administrator for assistance!", "error");
         }
      } catch (err) {
         handleError(err);
      }
   }

   async function closeNotesModal() {
      try {
         setNotesModal(false);
         getApplication();
      } catch (err) {
         openAlertMessage("An error has occurred. Please reach out to administration for assistance.", "error");
      }
   }

   async function handleDeleteClose() {
      try {
         setDeleteModal(false);
         getApplication();
      } catch (err) {
         handleError(err);
      }
   }

   function checkIfExpired(date: Date) {
      try {
         const formattedDate = new Date(date);

         if (formattedDate.setDate(formattedDate.getDate() + 30) < Date.now()) {
            return true;
         }

         return false;
      } catch (err) {
         handleError(err);
      }
   }

   function handleFileChange(event: ChangeEvent<HTMLInputElement>): void {
      if (event.target.files != null) {
         setFileUpload(event.target.files[0]);
         setFileUploadModal(true);
      }
   }

   async function handleDownloadClick() {
      if (!selectedApplication.tradeReferencesFileName) return;

      setDownloading(true);

      try {
         const response = await getTradeReferencesPdf(selectedApplication._id);

         if (!response.data) {
            openAlertMessage(
               "Error loading Trade References PDF. Please contact your administrator for assistance!",
               "error",
            );
         }

         saveAs(response.data, selectedApplication.tradeReferencesFileName);
      } catch (err) {
         openAlertMessage(
            "Error loading Trade References PDF. Please contact your administrator for assistance!",
            "error",
         );
      } finally {
         setDownloading(false);
      }
   }

   async function getTradeReferencesDate() {
      try {
         const responseData = await getTradeReferencesLastModifiedDate(match.params.id);

         if (responseData.success) {
            setFileDate(responseData.data);
         }
      } catch (err) {
         handleError(err);
      }
   }

   async function uploadTradeReferencesFile() {
      try {
         setFileUploadModal(false);
         setUploadClicked(true);

         const formData = new FormData();

         if (fileUpload != null) {
            // set body keys
            formData.append("TradeReferenceDocument", fileUpload);
            formData.append("Application_id", selectedApplication._id);
            formData.append("FileName", fileUpload.name);

            const responseData = await setTradeReferencesDocument(formData);

            if (responseData.success) {
               openAlertMessage("Successfully Uploaded Trade References.", "success");
               // update the filename in the card
               setSelectedApplication((app) => ({
                  ...app,
                  tradeReferencesFileName: responseData.data.tradeReferencesFileName,
               }));
               setUploadClicked(false);
            } else {
               openAlertMessage(responseData.message, "warning");
               setUploadClicked(false);
               return;
            }
         }
      } catch (err) {
         setUploadClicked(false);
         handleError(err);
      }

      getTradeReferencesDate();
   }

   return (
      <>
         <div className="ApplicationDetails">
            <div className="ApplicationDetails-root">
               {loading ? (
                  <>
                     <CheckAnimation />
                  </>
               ) : (
                  <>
                     <div className="ApplicationDetails-header">
                        <div
                           className={
                              drawerState.DrawerOpen === true ? classes.headerDrawerOpen : classes.headerDrawerClosed
                           }
                        >
                           <Grid
                              xs={12}
                              container
                              direction="row"
                              justify="space-between"
                              alignItems="center"
                              style={{ height: "60px" }}
                           >
                              <Grid
                                 container
                                 direction="column"
                                 item
                                 xs={mobileScreen ? 7 : 9}
                                 justify="center"
                                 alignItems="flex-start"
                                 style={{ height: "60px" }}
                              >
                                 <Breadcrumbs separator={<NavigateNextIcon />}>
                                    <Link
                                       color="inherit"
                                       style={{ cursor: "pointer" }}
                                       onClick={() => breadcrumbOnClick("/applications")}
                                    >
                                       <Typography variant="h6" style={{ fontWeight: 700 }}>
                                          {"Applications"}
                                       </Typography>
                                    </Link>
                                    <Link
                                       color="inherit"
                                       style={{ cursor: "pointer" }}
                                       onClick={() => breadcrumbOnClick("/applications/details/" + match.params.id)}
                                    >
                                       <Typography variant="h6" style={{ fontWeight: 700 }}>
                                          {selectedApplication.applicationNumber
                                             ? selectedApplication.applicationNumber
                                             : selectedApplication.email}
                                       </Typography>
                                    </Link>
                                 </Breadcrumbs>
                              </Grid>
                              <Grid
                                 container
                                 direction="column"
                                 item
                                 xs={mobileScreen ? 5 : 3}
                                 justify="center"
                                 alignItems="flex-end"
                                 style={{ height: "60px" }}
                              >
                                 {selectedApplication.status.toLowerCase() === "sent" ? (
                                    <ButtonGroup>
                                       <Button
                                          onClick={() => saveApplication()}
                                          variant="contained"
                                          color="primary"
                                          classes={{
                                             root: classes.saveButton,
                                             label: mobileScreen ? classes.labelMobile : classes.label,
                                          }}
                                          style={{ width: "100px" }}
                                          disabled={
                                             buttonClicked ||
                                             resendClicked ||
                                             userState.CurrentUser.security.toUpperCase() === "READ ONLY"
                                          }
                                       >
                                          {buttonClicked ? (
                                             <CircularProgress size={20} className={classes.buttonProgress} />
                                          ) : (
                                             <SaveIcon />
                                          )}
                                          Save
                                       </Button>
                                       <Button
                                          onClick={() => saveApplication(true)}
                                          variant="contained"
                                          color="primary"
                                          classes={{
                                             label: mobileScreen ? classes.labelMobile : classes.label,
                                          }}
                                          style={{ width: "100px" }}
                                          disabled={
                                             buttonClicked ||
                                             resendClicked ||
                                             userState.CurrentUser.security.toUpperCase() === "READ ONLY"
                                          }
                                       >
                                          {resendClicked ? (
                                             <CircularProgress size={20} className={classes.buttonProgress} />
                                          ) : (
                                             <MailIcon />
                                          )}
                                          Resend
                                       </Button>
                                       <Button
                                          onClick={() => handleAppLinkClick()}
                                          variant="contained"
                                          color="primary"
                                          classes={{
                                             root: linkClicked ? classes.saveButton : undefined,
                                             label: mobileScreen ? classes.labelMobile : classes.label,
                                          }}
                                          style={{ width: "100px" }}
                                          disabled={userState.CurrentUser.security.toUpperCase() === "READ ONLY"}
                                       >
                                          {linkClicked ? <CheckIcon /> : <LinkIcon />}
                                          {linkClicked ? "Copied" : "App Link"}
                                       </Button>
                                    </ButtonGroup>
                                 ) : (
                                    <ButtonGroup>
                                       <Button
                                          onClick={() => saveApplication()}
                                          variant="contained"
                                          color="primary"
                                          classes={{
                                             root: classes.saveButton,
                                             label: mobileScreen ? classes.labelMobile : classes.label,
                                          }}
                                          style={{ width: "100px" }}
                                          disabled={
                                             buttonClicked ||
                                             resendClicked ||
                                             userState.CurrentUser.security.toUpperCase() === "READ ONLY"
                                          }
                                       >
                                          {buttonClicked ? (
                                             <CircularProgress size={20} className={classes.buttonProgress} />
                                          ) : (
                                             <SaveIcon />
                                          )}
                                          Save
                                       </Button>
                                       <Button
                                          onClick={() => setApprovalModal(true)}
                                          variant="contained"
                                          color="primary"
                                          classes={{
                                             label: mobileScreen ? classes.labelMobile : classes.label,
                                          }}
                                          style={{ width: "100px" }}
                                          disabled={
                                             selectedApplication.status === "DENIED" ||
                                             selectedApplication.status === "APPROVED" ||
                                             userState.CurrentUser.security.toUpperCase() === "READ ONLY"
                                          }
                                       >
                                          <ThumbUpIcon />
                                          Approve
                                       </Button>
                                       <Button
                                          onClick={() => setDenialModal(true)}
                                          variant="contained"
                                          color="primary"
                                          classes={{
                                             label: mobileScreen ? classes.labelMobile : classes.label,
                                          }}
                                          style={{ width: "100px" }}
                                          disabled={
                                             selectedApplication.status === "DENIED" ||
                                             selectedApplication.status === "APPROVED" ||
                                             userState.CurrentUser.security.toUpperCase() === "READ ONLY"
                                          }
                                       >
                                          <ThumbDownIcon />
                                          Deny
                                       </Button>
                                    </ButtonGroup>
                                 )}
                              </Grid>
                           </Grid>
                        </div>
                     </div>
                     <div
                        className={
                           drawerState.DrawerOpen === true ? classes.headerDrawerOpen : classes.headerDrawerClosed
                        }
                     >
                        <Collapse in={alertMessage ? true : false}>
                           <Alert
                              variant="filled"
                              severity={alertType}
                              action={
                                 <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                       setAlertMessage("");
                                    }}
                                 >
                                    <CloseIcon />
                                 </IconButton>
                              }
                           >
                              {alertMessage}
                           </Alert>
                        </Collapse>
                        <Card style={{ borderRadius: 0 }}>
                           <Grid container direction={"row"}>
                              <Grid direction={"column"} item xs={9}>
                                 {selectedApplication.status.toLowerCase() === "sent" ? (
                                    <></>
                                 ) : (
                                    <Grid
                                       container
                                       direction={"row"}
                                       style={{
                                          display: "flex",
                                          justifyContent: "flex-start",
                                       }}
                                    >
                                       <Grid
                                          item
                                          xs={12}
                                          style={{
                                             display: "flex",
                                             justifyContent: "flex-start",
                                          }}
                                       >
                                          <Typography
                                             variant="h5"
                                             style={{
                                                color: "#010440",
                                                fontWeight: 700,
                                             }}
                                          >
                                             {selectedApplication.companyName +
                                                " - " +
                                                selectedApplication.applicationNumber}
                                          </Typography>
                                       </Grid>
                                    </Grid>
                                 )}
                                 <Grid
                                    container
                                    direction={"row"}
                                    style={{
                                       height: "30px",
                                    }}
                                    justify="space-between"
                                 >
                                    {selectedApplication.status.toLowerCase() === "sent" ? (
                                       <>
                                          <Grid item xs={3}>
                                             <Typography
                                                variant="h5"
                                                noWrap
                                                style={{ color: "#010440", fontWeight: 700 }}
                                             >
                                                Date Application Sent
                                             </Typography>
                                          </Grid>
                                          <Grid item xs={3}>
                                             <Typography
                                                variant="h5"
                                                noWrap
                                                style={{ color: "#010440", fontWeight: 700 }}
                                             >
                                                Company | Division
                                             </Typography>
                                          </Grid>
                                          <Grid item xs={3}>
                                             <Typography
                                                variant="h5"
                                                noWrap
                                                style={{ color: "#010440", fontWeight: 700 }}
                                             >
                                                Email
                                             </Typography>
                                          </Grid>
                                          <Grid item xs={3}>
                                             <Typography
                                                variant="h5"
                                                noWrap
                                                style={{ color: "#010440", fontWeight: 700 }}
                                             >
                                                Email Status
                                             </Typography>
                                          </Grid>
                                       </>
                                    ) : (
                                       <>
                                          <Grid
                                             item
                                             xs={
                                                selectedApplication.status === "APPROVED" ||
                                                selectedApplication.status === "DENIED"
                                                   ? 2
                                                   : 3
                                             }
                                          >
                                             <Typography
                                                variant="h5"
                                                noWrap
                                                style={{ color: "#010440", fontWeight: 700 }}
                                             >
                                                Date Received
                                             </Typography>
                                          </Grid>
                                          <Grid
                                             item
                                             xs={
                                                selectedApplication.status === "APPROVED" ||
                                                selectedApplication.status === "DENIED"
                                                   ? 2
                                                   : 3
                                             }
                                          >
                                             <Typography
                                                variant="h5"
                                                noWrap
                                                style={{ color: "#010440", fontWeight: 700 }}
                                             >
                                                Status
                                             </Typography>
                                          </Grid>
                                          {selectedApplication.status === "APPROVED" ||
                                          selectedApplication.status === "DENIED" ? (
                                             <Grid
                                                item
                                                xs={
                                                   selectedApplication.status === "APPROVED" ||
                                                   selectedApplication.status === "DENIED"
                                                      ? 2
                                                      : 3
                                                }
                                             >
                                                <Typography
                                                   variant="h5"
                                                   noWrap
                                                   style={{ color: "#010440", fontWeight: 700 }}
                                                >
                                                   {selectedApplication.status === "APPROVED"
                                                      ? "Payment Terms"
                                                      : "Denial Code"}
                                                </Typography>
                                             </Grid>
                                          ) : (
                                             <></>
                                          )}
                                          <Grid
                                             item
                                             xs={
                                                selectedApplication.status === "APPROVED" ||
                                                selectedApplication.status === "DENIED"
                                                   ? 2
                                                   : 3
                                             }
                                          >
                                             <Typography
                                                variant="h5"
                                                noWrap
                                                style={{ color: "#010440", fontWeight: 700 }}
                                             >
                                                Company | Division
                                             </Typography>
                                          </Grid>
                                          <Grid
                                             item
                                             xs={
                                                selectedApplication.status === "APPROVED" ||
                                                selectedApplication.status === "DENIED"
                                                   ? 2
                                                   : 3
                                             }
                                          >
                                             <Typography
                                                variant="h5"
                                                noWrap
                                                style={{ color: "#010440", fontWeight: 700 }}
                                             >
                                                Customer Code
                                             </Typography>
                                          </Grid>
                                       </>
                                    )}
                                 </Grid>
                                 <Grid
                                    container
                                    direction={"row"}
                                    justify="space-between"
                                    style={{
                                       height: "30px",
                                    }}
                                 >
                                    {selectedApplication.status.toLowerCase() === "sent" ? (
                                       <>
                                          <Grid item xs={3}>
                                             <Typography variant="h6">
                                                {DateTimeFormatterShortYear(selectedApplication.addDate)}
                                             </Typography>
                                          </Grid>
                                          <Grid item xs={3}>
                                             <Typography variant="h6">{selectedDivision}</Typography>
                                          </Grid>
                                          <Grid item xs={3}>
                                             <TextField
                                                id="tf-account"
                                                name="account"
                                                value={selectedApplication.email}
                                                onChange={(event) => onChange(event, "email")}
                                                inputProps={{ style: { fontSize: 16 } }}
                                                disabled={userState.CurrentUser.security.toUpperCase() === "READ ONLY"}
                                             />
                                          </Grid>
                                          <Grid item xs={3}>
                                             <div className={classes.chipContainer}>
                                                {selectedApplication.addDate ? (
                                                   checkIfExpired(selectedApplication.addDate) === true ? (
                                                      //Expired
                                                      <Chip
                                                         key={selectedApplication._id}
                                                         label="Expired"
                                                         size="small"
                                                         clickable={false}
                                                         className={classes.chipRed}
                                                      />
                                                   ) : selectedApplication.applicationOpened ? (
                                                      //Opened
                                                      <Chip
                                                         key={selectedApplication._id}
                                                         label="Opened"
                                                         size="small"
                                                         clickable={false}
                                                         className={classes.chipGreen}
                                                      />
                                                   ) : (
                                                      //Sent
                                                      <Chip
                                                         key={selectedApplication._id}
                                                         label="Sent"
                                                         size="small"
                                                         clickable={false}
                                                         color="primary"
                                                      />
                                                   )
                                                ) : (
                                                   //Expired. This is a safety measure.
                                                   <Chip
                                                      key={selectedApplication._id}
                                                      label="Sent"
                                                      size="small"
                                                      color="primary"
                                                      clickable={false}
                                                   />
                                                )}
                                             </div>
                                          </Grid>
                                       </>
                                    ) : (
                                       <>
                                          <Grid
                                             item
                                             xs={
                                                selectedApplication.status === "APPROVED" ||
                                                selectedApplication.status === "DENIED"
                                                   ? 2
                                                   : 3
                                             }
                                          >
                                             <Typography variant="h6">
                                                {DateTimeFormatterShortYear(selectedApplication.signatureDate)}
                                             </Typography>
                                          </Grid>
                                          <Grid
                                             item
                                             xs={
                                                selectedApplication.status === "APPROVED" ||
                                                selectedApplication.status === "DENIED"
                                                   ? 2
                                                   : 3
                                             }
                                          >
                                             <Typography variant="h6">{selectedApplication.status}</Typography>
                                          </Grid>
                                          {selectedApplication.status === "APPROVED" ? (
                                             <Grid item xs={2}>
                                                <Typography variant="h6">{selectedApplication.netTerms}</Typography>
                                             </Grid>
                                          ) : selectedApplication.status === "DENIED" ? (
                                             <Grid item xs={2}>
                                                <FormControl style={{ width: "100%" }}>
                                                   <Select
                                                      id="select-denialCodes"
                                                      defaultValue=""
                                                      value={selectedApplication.code}
                                                      onChange={(event) => onSelectChange(event)}
                                                      disabled={
                                                         userState.CurrentUser.security.toUpperCase() === "READ ONLY"
                                                      }
                                                   >
                                                      {denialCodesList?.map((denialCode) => {
                                                         return (
                                                            <MenuItem key={denialCode._id} value={denialCode.code}>
                                                               {denialCode.code}
                                                            </MenuItem>
                                                         );
                                                      })}
                                                   </Select>
                                                </FormControl>
                                             </Grid>
                                          ) : (
                                             <></>
                                          )}
                                          <Grid
                                             item
                                             xs={
                                                selectedApplication.status === "APPROVED" ||
                                                selectedApplication.status === "DENIED"
                                                   ? 2
                                                   : 3
                                             }
                                          >
                                             <Typography variant="h6">{selectedDivision}</Typography>
                                          </Grid>
                                          <Grid
                                             item
                                             xs={
                                                selectedApplication.status === "APPROVED" ||
                                                selectedApplication.status === "DENIED"
                                                   ? 2
                                                   : 3
                                             }
                                          >
                                             <TextField
                                                id="tf-account"
                                                name="account"
                                                value={selectedApplication.companyCode}
                                                onChange={(event) => onChange(event, "companyCode")}
                                                inputProps={{ style: { fontSize: 16 } }}
                                                disabled={userState.CurrentUser.security.toUpperCase() === "READ ONLY"}
                                             />
                                          </Grid>
                                       </>
                                    )}
                                 </Grid>
                                 <Grid
                                    container
                                    direction={"row"}
                                    justify="space-between"
                                    style={{
                                       height: "30px",
                                    }}
                                 >
                                    <Grid item xs={12}>
                                       <Typography variant="h5" noWrap style={{ color: "#010440", fontWeight: 700 }}>
                                          Point Of Contact
                                       </Typography>
                                    </Grid>
                                 </Grid>
                                 <Grid container direction={"row"} justify="space-between">
                                    <Grid item xs={12} style={{ display: "flex" }}>
                                       <Typography variant="h6">
                                          {contactList.map((contact, index) => {
                                             return (
                                                <>
                                                   {contact.name}
                                                   {index < contactList.length - 1 ? ", " : ""}
                                                </>
                                             );
                                          })}
                                       </Typography>
                                    </Grid>
                                 </Grid>
                              </Grid>
                              <Grid
                                 container
                                 direction="column"
                                 item
                                 xs={mobileScreen ? 5 : 3}
                                 justify="center"
                                 alignItems="flex-end"
                              >
                                 {selectedApplication.status.toLowerCase() === "sent" ? (
                                    <ButtonGroup>
                                       <Button
                                          onClick={() => setNotesModal(true)}
                                          variant="contained"
                                          color="primary"
                                          classes={{
                                             root: classes.notesButton,
                                             label: mobileScreen ? classes.labelMobile : classes.label,
                                          }}
                                          style={{ width: "150px" }}
                                       >
                                          <NoteIcon />
                                          {mobileScreen ? "Notes" : "Notes"}
                                       </Button>
                                       <Button
                                          onClick={() => handleDeleteClick()}
                                          variant="contained"
                                          color="secondary"
                                          classes={{
                                             label: mobileScreen ? classes.labelMobile : classes.label,
                                          }}
                                          style={{ width: "150px" }}
                                          disabled={deleting}
                                       >
                                          {deleting ? (
                                             <CircularProgress size={20} className={classes.buttonProgress} />
                                          ) : (
                                             <DeleteIcon />
                                          )}
                                          Delete
                                       </Button>
                                    </ButtonGroup>
                                 ) : selectedApplication.status.toLowerCase() === "approved" ? (
                                    <ButtonGroup>
                                       <Button
                                          onClick={() => setNotesModal(true)}
                                          variant="contained"
                                          color="primary"
                                          classes={{
                                             root: classes.notesButton,
                                             label: mobileScreen ? classes.labelMobile : classes.label,
                                          }}
                                          style={{ width: "80px" }}
                                       >
                                          <NoteIcon />
                                          {mobileScreen ? "Notes" : "Notes"}
                                       </Button>
                                       <Button
                                          onClick={() => handleEmailClick()}
                                          variant="contained"
                                          color="primary"
                                          classes={{
                                             label: mobileScreen ? classes.labelMobile : classes.label,
                                          }}
                                          style={{ width: "80px" }}
                                          disabled={emailing}
                                       >
                                          {emailing ? (
                                             <CircularProgress size={20} className={classes.buttonProgress} />
                                          ) : (
                                             <MailIcon />
                                          )}
                                          Email
                                       </Button>
                                       <Button
                                          onClick={() => handlePrintClick()}
                                          variant="contained"
                                          color="primary"
                                          classes={{
                                             label: mobileScreen ? classes.labelMobile : classes.label,
                                          }}
                                          style={{ width: "80px" }}
                                          disabled={printing}
                                       >
                                          {printing ? (
                                             <CircularProgress size={20} className={classes.buttonProgress} />
                                          ) : (
                                             <PrintIcon />
                                          )}
                                          Print
                                       </Button>
                                       <Button
                                          onClick={() => handleDocuSignClick()}
                                          variant="contained"
                                          color="primary"
                                          classes={{
                                             root: classes.docuSignButton,
                                             label: mobileScreen ? classes.labelMobile : classes.label,
                                          }}
                                          style={{ width: "80px" }}
                                          disabled={
                                             docuSign ||
                                             userState.CurrentUser.security.toUpperCase() === "READ ONLY" ||
                                             selectedApplication.docuSignCompleted !== true
                                          }
                                       >
                                          {docuSign ? (
                                             <CircularProgress size={20} className={classes.buttonProgress} />
                                          ) : (
                                             <DescriptionIcon />
                                          )}
                                          DocuSign
                                       </Button>
                                    </ButtonGroup>
                                 ) : (
                                    <ButtonGroup>
                                       <Button
                                          onClick={() => setNotesModal(true)}
                                          variant="contained"
                                          color="primary"
                                          classes={{
                                             root: classes.notesButton,
                                             label: mobileScreen ? classes.labelMobile : classes.label,
                                          }}
                                          style={{ width: "100px" }}
                                       >
                                          <NoteIcon />
                                          {mobileScreen ? "Notes" : "Notes"}
                                       </Button>
                                       <Button
                                          onClick={() => handleEmailClick()}
                                          variant="contained"
                                          color="primary"
                                          classes={{
                                             label: mobileScreen ? classes.labelMobile : classes.label,
                                          }}
                                          style={{ width: "100px" }}
                                          disabled={emailing}
                                       >
                                          {emailing ? (
                                             <CircularProgress size={20} className={classes.buttonProgress} />
                                          ) : (
                                             <MailIcon />
                                          )}
                                          Email
                                       </Button>
                                       <Button
                                          onClick={() => handlePrintClick()}
                                          variant="contained"
                                          color="primary"
                                          classes={{
                                             label: mobileScreen ? classes.labelMobile : classes.label,
                                          }}
                                          style={{ width: "100px" }}
                                          disabled={printing}
                                       >
                                          {printing ? (
                                             <CircularProgress size={20} className={classes.buttonProgress} />
                                          ) : (
                                             <PrintIcon />
                                          )}
                                          Print
                                       </Button>
                                    </ButtonGroup>
                                 )}
                              </Grid>
                           </Grid>
                        </Card>
                        <Divider className={classes.divider} />
                        <div className="ApplicationDetails-body">
                           <div
                              className={
                                 drawerState.DrawerOpen === true
                                    ? "ApplicationDetails-layout-drawer-open"
                                    : "ApplicationDetails-layout"
                              }
                           >
                              <Grid container direction={"row"} item xs={12} style={{ paddingTop: "0" }}>
                                 <Grid
                                    container
                                    direction={"column"}
                                    item
                                    xs={mobileScreen ? 12 : 6}
                                    style={{ paddingTop: "0" }}
                                 >
                                    {detailsCard("Company Information", [
                                       [
                                          { label: "Company Name", value: selectedApplication.companyName },
                                          { label: "Company Contact", value: selectedApplication.companyContact },
                                          {
                                             label: "Company Established",
                                             value: DateFormatter(selectedApplication.companyEstablishedDate),
                                          },
                                       ],
                                       [
                                          {
                                             label: "Accounts Payable Email",
                                             value: selectedApplication.accountsPayableEmail,
                                          },
                                          { label: "Address 1", value: selectedApplication.address.address1 },
                                          { label: "Address 2", value: selectedApplication.address.address2 },
                                       ],
                                       [
                                          { label: "City", value: selectedApplication.address.city },
                                          { label: "State", value: selectedApplication.address.state },
                                          { label: "Zip Code", value: selectedApplication.address.zip },
                                          { label: "Country", value: selectedApplication.address.country },
                                       ],
                                       [
                                          { label: "Email", value: selectedApplication.companyEmail },
                                          { label: "Phone", value: selectedApplication.companyPhone },
                                          { label: "WebSite", value: selectedApplication.companyWebsite },
                                          { label: "C-TPAT Number", value: selectedApplication.ctpatNumber },
                                       ],
                                    ])}
                                    <Card style={{ width: "100%", marginTop: theme.spacing(5) }}>
                                       <CardHeader
                                          className={"ApplicationDetails-card-header1"}
                                          title={<Typography variant="h5">{"Banking Information"}</Typography>}
                                          style={{ height: "4rem" }}
                                       />
                                       <Divider className={classes.divider} />
                                       <CardContent style={{ height: "280px" }}>
                                          <Grid
                                             container
                                             direction={"row"}
                                             style={{
                                                height: "30px",
                                             }}
                                             justify="space-between"
                                          >
                                             <Grid item xs={4} style={{ color: "#010440", fontWeight: 700 }}>
                                                <Typography
                                                   variant="h6"
                                                   noWrap
                                                   style={{ color: "#010440", fontWeight: 700 }}
                                                >
                                                   D&B D-U-N-S Number
                                                </Typography>
                                             </Grid>
                                             <Grid item xs={4}>
                                                <Typography
                                                   variant="h6"
                                                   noWrap
                                                   style={{ color: "#010440", fontWeight: 700 }}
                                                >
                                                   Bank Name
                                                </Typography>
                                             </Grid>
                                             <Grid item xs={4}>
                                                <Typography
                                                   variant="h6"
                                                   noWrap
                                                   style={{ color: "#010440", fontWeight: 700 }}
                                                >
                                                   Name of Principal
                                                </Typography>
                                             </Grid>
                                          </Grid>
                                          <Grid container direction={"row"} justify="space-between">
                                             <Grid item xs={4}>
                                                <Typography>
                                                   {DUNSFormatter(selectedApplication.bankInfo.duns)}
                                                </Typography>
                                             </Grid>
                                             <Grid item xs={4}>
                                                <Typography>{selectedApplication.bankInfo.bankName}</Typography>
                                             </Grid>
                                             <Grid item xs={4}>
                                                <Typography>{selectedApplication.bankInfo.principalName}</Typography>
                                             </Grid>
                                          </Grid>
                                          <Grid
                                             container
                                             direction={"row"}
                                             style={{
                                                height: "30px",
                                             }}
                                             justify="space-between"
                                          >
                                             <Grid item xs={3}>
                                                <Typography
                                                   variant="h6"
                                                   noWrap
                                                   style={{ color: "#010440", fontWeight: 700 }}
                                                >
                                                   Account Number
                                                </Typography>
                                             </Grid>
                                             <Grid item xs={3}>
                                                <Typography
                                                   variant="h6"
                                                   noWrap
                                                   style={{ color: "#010440", fontWeight: 700 }}
                                                >
                                                   Contact
                                                </Typography>
                                             </Grid>
                                             <Grid item xs={3}>
                                                <Typography
                                                   variant="h6"
                                                   noWrap
                                                   style={{ color: "#010440", fontWeight: 700 }}
                                                >
                                                   Phone #
                                                </Typography>
                                             </Grid>
                                             <Grid item xs={3}>
                                                <Typography
                                                   variant="h6"
                                                   noWrap
                                                   style={{ color: "#010440", fontWeight: 700 }}
                                                >
                                                   Address
                                                </Typography>
                                             </Grid>
                                          </Grid>
                                          <Grid container direction={"row"} justify="space-between">
                                             <Grid item xs={3}>
                                                <Typography>{selectedApplication.bankInfo.accountNumber}</Typography>
                                             </Grid>
                                             <Grid item xs={3}>
                                                <Typography>{selectedApplication.bankInfo.contact}</Typography>
                                             </Grid>
                                             <Grid item xs={3}>
                                                <Typography>
                                                   {PhoneFormatter(selectedApplication.bankInfo.phone)}
                                                </Typography>
                                             </Grid>
                                             <Grid item xs={3}>
                                                <Typography>{selectedApplication.bankInfo.address1}</Typography>
                                             </Grid>
                                          </Grid>
                                          <Grid
                                             container
                                             direction={"row"}
                                             style={{
                                                height: "30px",
                                             }}
                                             justify="space-between"
                                          >
                                             <Grid item xs={4}>
                                                <Typography
                                                   variant="h6"
                                                   noWrap
                                                   style={{ color: "#010440", fontWeight: 700 }}
                                                >
                                                   City
                                                </Typography>
                                             </Grid>
                                             <Grid item xs={2}>
                                                <Typography
                                                   variant="h6"
                                                   noWrap
                                                   style={{ color: "#010440", fontWeight: 700 }}
                                                >
                                                   State
                                                </Typography>
                                             </Grid>
                                             <Grid item xs={2}>
                                                <Typography
                                                   variant="h6"
                                                   noWrap
                                                   style={{ color: "#010440", fontWeight: 700 }}
                                                >
                                                   Zip Code
                                                </Typography>
                                             </Grid>
                                             <Grid item xs={4}>
                                                <Typography
                                                   variant="h6"
                                                   noWrap
                                                   style={{ color: "#010440", fontWeight: 700 }}
                                                >
                                                   Country
                                                </Typography>
                                             </Grid>
                                          </Grid>
                                          <Grid container direction={"row"} justify="space-between">
                                             <Grid item xs={4}>
                                                <Typography>{selectedApplication.bankInfo.city}</Typography>
                                             </Grid>
                                             <Grid item xs={2}>
                                                <Typography>{selectedApplication.bankInfo.state}</Typography>
                                             </Grid>
                                             <Grid item xs={2}>
                                                <Typography>{selectedApplication.bankInfo.zip}</Typography>
                                             </Grid>
                                             <Grid item xs={4}>
                                                <Typography>{selectedApplication.bankInfo.country}</Typography>
                                             </Grid>
                                          </Grid>
                                          <Grid
                                             container
                                             direction={"row"}
                                             style={{
                                                height: "30px",
                                             }}
                                             justify="space-between"
                                          >
                                             <Grid item xs={6}>
                                                <Typography
                                                   variant="h6"
                                                   noWrap
                                                   style={{ color: "#010440", fontWeight: 700 }}
                                                >
                                                   Fed Tax ID #
                                                </Typography>
                                             </Grid>
                                             <Grid item xs={6}>
                                                <Typography
                                                   variant="h6"
                                                   noWrap
                                                   style={{ color: "#010440", fontWeight: 700 }}
                                                >
                                                   Payment Method
                                                </Typography>
                                             </Grid>
                                          </Grid>
                                          <Grid container direction={"row"} justify="space-between">
                                             <Grid item xs={6}>
                                                <Typography>{selectedApplication.bankInfo.federalTaxNumber}</Typography>
                                             </Grid>
                                             <Grid item xs={6}>
                                                <Typography>{selectedApplication.bankInfo.paymentMethod}</Typography>
                                             </Grid>
                                          </Grid>
                                       </CardContent>
                                    </Card>
                                    {detailsCard("CHB Information", [
                                       [
                                          {
                                             label: "UWL handling CHB",
                                             value: selectedApplication.chb.uwlHandleChb ? "YES" : "NO",
                                          },
                                          {
                                             label: "Importer ACH with U.S. Customs",
                                             value: selectedApplication.chb.achImporter ? "YES" : "NO",
                                          },
                                       ],
                                       [
                                          { label: "Company Name", value: selectedApplication.chb.companyName },
                                          { label: "Contact", value: selectedApplication.chb.contact },
                                          { label: "Address", value: selectedApplication.chb.address },
                                          { label: "Email", value: selectedApplication.chb.email },
                                       ],
                                       [
                                          { label: "City", value: selectedApplication.chb.city },
                                          { label: "State", value: selectedApplication.chb.state },
                                          { label: "Zip Code", value: selectedApplication.chb.zip },
                                          { label: "Phone", value: PhoneFormatter(selectedApplication.chb.phone) },
                                       ],
                                    ])}
                                    {/* Trade References File Name */}
                                    <Card style={{ width: "100%", marginTop: theme.spacing(5) }}>
                                       <CardHeader
                                          className={"ApplicationDetails-card-header1"}
                                          style={{ height: "4rem" }}
                                          title={<Typography variant="h5">{"Trade References File"}</Typography>}
                                       />
                                       <Divider className={classes.divider} />
                                       <CardContent>
                                          <TableContainer>
                                             <Table style={{ marginBottom: "1rem" }}>
                                                <TableHead>
                                                   <TableRow>
                                                      <TableCell style={{ fontSize: "16px", fontWeight: 700 }}>
                                                         {" "}
                                                         File Name{" "}
                                                      </TableCell>
                                                      <TableCell style={{ fontSize: "16px", fontWeight: 700 }}>
                                                         {" "}
                                                         Date Added{" "}
                                                      </TableCell>
                                                      <TableCell style={{ fontSize: "16px", fontWeight: 700 }} />
                                                   </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                   <TableRow key={`tr-${selectedApplication._id}`}>
                                                      <TableCell style={{ fontSize: "16px" }}>
                                                         {" "}
                                                         {selectedApplication.tradeReferencesFileName ?? ""}{" "}
                                                      </TableCell>
                                                      <TableCell style={{ fontSize: "16px" }}>
                                                         {fileDate ? DateFormatter(fileDate) : ""}
                                                      </TableCell>
                                                      <TableCell>
                                                         <ButtonGroup>
                                                            <Tooltip title="Upload" arrow>
                                                               <IconButton
                                                                  component="label"
                                                                  disabled={
                                                                     userState.CurrentUser.security.toUpperCase() ===
                                                                     "READ ONlY"
                                                                  }
                                                                  color="primary"
                                                                  classes={{
                                                                     label: mobileScreen
                                                                        ? classes.labelMobile
                                                                        : classes.label,
                                                                  }}
                                                               >
                                                                  <input
                                                                     type="file"
                                                                     hidden
                                                                     accept=".pdf,.doc,.docx"
                                                                     onChange={handleFileChange}
                                                                  />

                                                                  {uploadClicked ? (
                                                                     <CircularProgress
                                                                        size={16}
                                                                        className={classes.buttonProgress}
                                                                     />
                                                                  ) : (
                                                                     <PublishIcon />
                                                                  )}
                                                               </IconButton>
                                                            </Tooltip>
                                                            <Tooltip title="Download" arrow>
                                                               <IconButton
                                                                  disabled={
                                                                     !selectedApplication.tradeReferencesFileName
                                                                  }
                                                                  color="secondary"
                                                                  onClick={() => handleDownloadClick()}
                                                               >
                                                                  {downloading ? (
                                                                     <CircularProgress
                                                                        size={16}
                                                                        className={classes.buttonProgress}
                                                                     />
                                                                  ) : (
                                                                     <DownloadIcon />
                                                                  )}
                                                               </IconButton>
                                                            </Tooltip>
                                                         </ButtonGroup>
                                                      </TableCell>
                                                   </TableRow>
                                                </TableBody>
                                             </Table>
                                          </TableContainer>
                                       </CardContent>
                                    </Card>
                                 </Grid>
                                 <Grid
                                    container
                                    direction={"column"}
                                    item
                                    xs={mobileScreen ? 12 : 6}
                                    style={{ paddingTop: 0 }}
                                 >
                                    {detailsCard("Billing Information", [
                                       [
                                          { label: "Address 1", value: selectedApplication.billingAddress.address1 },
                                          { label: "Address 2", value: selectedApplication.billingAddress.address2 },
                                       ],
                                       [
                                          { label: "City", value: selectedApplication.billingAddress.city },
                                          { label: "State", value: selectedApplication.billingAddress.state },
                                          { label: "Zip Code", value: selectedApplication.billingAddress.zip },
                                       ],
                                       [
                                          { label: "Email", value: selectedApplication.billingAddress.email },
                                          {
                                             label: "Phone",
                                             value: PhoneFormatter(selectedApplication.billingAddress.phone),
                                          },
                                          { label: "Contact", value: selectedApplication.billingAddress.contact },
                                       ],
                                    ])}
                                    <Card style={{ width: "100%", marginTop: theme.spacing(5) }}>
                                       <CardHeader
                                          className={"ApplicationDetails-card-header1"}
                                          style={{ height: "4rem" }}
                                          title={<Typography variant="h5">{"Credit Request"}</Typography>}
                                       />
                                       <Divider className={classes.divider} />
                                       <CardContent style={{ height: "280px" }}>
                                          <Grid
                                             container
                                             direction={"row"}
                                             style={{
                                                height: "30px",
                                             }}
                                             justify="space-between"
                                          >
                                             <Grid item xs={4}>
                                                <Typography
                                                   variant="h6"
                                                   noWrap
                                                   style={{ color: "#010440", fontWeight: 700 }}
                                                >
                                                   Credit Amount Requested
                                                </Typography>
                                             </Grid>
                                             <Grid item xs={4}>
                                                <Typography
                                                   variant="h6"
                                                   noWrap
                                                   style={{ color: "#010440", fontWeight: 700 }}
                                                >
                                                   Credit Amount Approved
                                                </Typography>
                                             </Grid>
                                             <Grid item xs={4}>
                                                <Typography
                                                   variant="h6"
                                                   noWrap
                                                   style={{ color: "#010440", fontWeight: 700 }}
                                                >
                                                   Annual Revenue
                                                </Typography>
                                             </Grid>
                                          </Grid>
                                          <Grid container direction={"row"} justify="space-between">
                                             <Grid item xs={4}>
                                                <Typography>
                                                   {`$${selectedApplication.amountReq.toLocaleString(undefined, {
                                                      maximumFractionDigits: 2,
                                                   })}`}
                                                </Typography>
                                             </Grid>
                                             <Grid item xs={4}>
                                                <Typography>
                                                   {`$${selectedApplication.approvedAmount.toLocaleString(undefined, {
                                                      maximumFractionDigits: 2,
                                                   })}`}
                                                </Typography>
                                             </Grid>
                                             <Grid item xs={4}>
                                                <Typography>
                                                   {`$${selectedApplication.annualRevenue.toLocaleString(undefined, {
                                                      maximumFractionDigits: 2,
                                                   })}`}
                                                </Typography>
                                             </Grid>
                                          </Grid>
                                          <Grid
                                             container
                                             direction={"row"}
                                             style={{
                                                height: "30px",
                                             }}
                                             justify="space-between"
                                          >
                                             <Grid item xs={4}>
                                                <Typography
                                                   variant="h6"
                                                   noWrap
                                                   style={{ color: "#010440", fontWeight: 700 }}
                                                >
                                                   # of Employees
                                                </Typography>
                                             </Grid>
                                             <Grid item xs={4}>
                                                <Typography
                                                   variant="h6"
                                                   noWrap
                                                   style={{ color: "#010440", fontWeight: 700 }}
                                                >
                                                   Preferred Method For Invoicing
                                                </Typography>
                                             </Grid>
                                             <Grid item xs={4}>
                                                <Typography
                                                   variant="h6"
                                                   noWrap
                                                   style={{ color: "#010440", fontWeight: 700 }}
                                                >
                                                   Ownership
                                                </Typography>
                                             </Grid>
                                          </Grid>
                                          <Grid container direction={"row"} justify="space-between">
                                             <Grid item xs={4}>
                                                <Typography>
                                                   {`${selectedApplication.numberEmployees.toLocaleString(undefined, {
                                                      maximumFractionDigits: 0,
                                                   })}`}
                                                </Typography>
                                             </Grid>
                                             <Grid item xs={4}>
                                                <Typography>
                                                   {selectedApplication.method.code === "Other" ||
                                                   selectedApplication.method.code === "Email"
                                                      ? `${selectedApplication.method.code}: ${selectedApplication.method.value}`
                                                      : selectedApplication.method.code}
                                                </Typography>
                                             </Grid>
                                             <Grid item xs={4}>
                                                <Typography>{selectedApplication.ownership}</Typography>
                                             </Grid>
                                          </Grid>
                                          <Grid
                                             container
                                             direction={"row"}
                                             style={{
                                                height: "30px",
                                             }}
                                             justify="space-between"
                                          >
                                             <Grid item xs={12}>
                                                <Typography
                                                   variant="h6"
                                                   noWrap
                                                   style={{ color: "#010440", fontWeight: 700 }}
                                                >
                                                   Documents Required With Invoice
                                                </Typography>
                                             </Grid>
                                          </Grid>
                                          <Grid container direction={"row"} justify="space-between">
                                             <Grid item xs={12}>
                                                <Typography>{selectedApplication.documents.join(", ")}</Typography>
                                             </Grid>
                                          </Grid>
                                          <Grid
                                             container
                                             direction={"row"}
                                             style={{
                                                height: "30px",
                                             }}
                                          />
                                          <Grid container direction={"row"} justify="space-between">
                                             <Grid item xs={12}>
                                                <Typography>&nbsp;</Typography>
                                             </Grid>
                                          </Grid>
                                       </CardContent>
                                    </Card>
                                    <Card style={{ width: "100%", marginTop: theme.spacing(5) }}>
                                       <CardHeader
                                          className={"ApplicationDetails-card-header1"}
                                          title={<Typography variant="h5">{"E-Signature"}</Typography>}
                                          style={{ height: "4rem" }}
                                       />
                                       <Divider className={classes.divider} />
                                       <CardContent style={{ height: "280px" }}>
                                          <Grid
                                             container
                                             direction={"row"}
                                             style={{
                                                height: "30px",
                                             }}
                                             justify="space-between"
                                          >
                                             <Grid item xs={6}>
                                                <Typography
                                                   variant="h6"
                                                   noWrap
                                                   style={{ color: "#010440", fontWeight: 700 }}
                                                >
                                                   Signature
                                                </Typography>
                                             </Grid>
                                             <Grid item xs={6}>
                                                <Typography
                                                   variant="h6"
                                                   noWrap
                                                   style={{ color: "#010440", fontWeight: 700 }}
                                                >
                                                   Date
                                                </Typography>
                                             </Grid>
                                          </Grid>
                                          <Grid container direction={"row"} justify="space-between">
                                             <Grid item xs={6}>
                                                <Typography
                                                   style={{ fontFamily: '"Brush Script MT"', fontSize: "22px" }}
                                                >
                                                   {selectedApplication.signature}
                                                </Typography>
                                             </Grid>
                                             <Grid item xs={6}>
                                                <Typography>
                                                   {DateTimeFormatterShortYear(selectedApplication.signatureDate)}
                                                </Typography>
                                             </Grid>
                                          </Grid>
                                          <Grid
                                             container
                                             direction={"row"}
                                             style={{
                                                height: "30px",
                                             }}
                                             justify="space-between"
                                          >
                                             <Grid item xs={6}>
                                                <Typography
                                                   variant="h6"
                                                   noWrap
                                                   style={{ color: "#010440", fontWeight: 700 }}
                                                >
                                                   Printed Name
                                                </Typography>
                                             </Grid>
                                             <Grid item xs={6}>
                                                <Typography
                                                   variant="h6"
                                                   noWrap
                                                   style={{ color: "#010440", fontWeight: 700 }}
                                                >
                                                   Title
                                                </Typography>
                                             </Grid>
                                          </Grid>
                                          <Grid container direction={"row"} justify="space-between">
                                             <Grid item xs={6}>
                                                <Typography>{selectedApplication.signature}</Typography>
                                             </Grid>
                                             <Grid item xs={6}>
                                                <Typography>{selectedApplication.signatureTitle}</Typography>
                                             </Grid>
                                          </Grid>
                                       </CardContent>
                                    </Card>
                                    <Card style={{ width: "100%", marginTop: theme.spacing(5) }}>
                                       <CardHeader
                                          className={"ApplicationDetails-card-header1"}
                                          style={{ height: "4rem" }}
                                          title={<Typography variant="h5">{"Trade References"}</Typography>}
                                       />
                                       <Divider className={classes.divider} />
                                       <CardContent>
                                          {selectedApplication.tradeRefInfo.map((trade, index) => {
                                             return (
                                                <>
                                                   <Paper>
                                                      <h3
                                                         style={{
                                                            backgroundColor: "#010440",
                                                            color: "white",
                                                            borderRadius: "4px",
                                                         }}
                                                      >
                                                         {`Reference ${index + 1}`}
                                                         <Divider className={classes.divider} />
                                                      </h3>
                                                      <Grid
                                                         container
                                                         direction={"row"}
                                                         style={{
                                                            height: "30px",
                                                         }}
                                                         justify="space-between"
                                                         key={`gridrowlabel-${index}`}
                                                      >
                                                         <Grid item xs={3}>
                                                            <Typography
                                                               variant="h6"
                                                               noWrap
                                                               style={{ color: "#010440", fontWeight: 700 }}
                                                            >
                                                               Company
                                                            </Typography>
                                                         </Grid>
                                                         <Grid item xs={3}>
                                                            <Typography
                                                               variant="h6"
                                                               noWrap
                                                               style={{ color: "#010440", fontWeight: 700 }}
                                                            >
                                                               Contact
                                                            </Typography>
                                                         </Grid>
                                                         <Grid item xs={3}>
                                                            <Typography
                                                               variant="h6"
                                                               noWrap
                                                               style={{ color: "#010440", fontWeight: 700 }}
                                                            >
                                                               Email
                                                            </Typography>
                                                         </Grid>
                                                         <Grid item xs={3}>
                                                            <Typography
                                                               variant="h6"
                                                               noWrap
                                                               style={{ color: "#010440", fontWeight: 700 }}
                                                            >
                                                               Phone #
                                                            </Typography>
                                                         </Grid>
                                                      </Grid>
                                                      <Grid
                                                         container
                                                         direction={"row"}
                                                         justify="space-between"
                                                         key={`gridrowvalue-${index}`}
                                                      >
                                                         <Grid item xs={3}>
                                                            <Typography>{trade.company}</Typography>
                                                         </Grid>
                                                         <Grid item xs={3}>
                                                            <Typography>{trade.contact}</Typography>
                                                         </Grid>
                                                         <Grid item xs={3}>
                                                            <Typography>{trade.email}</Typography>
                                                         </Grid>
                                                         <Grid item xs={3}>
                                                            <Typography>{PhoneFormatter(trade.phone)}</Typography>
                                                         </Grid>
                                                      </Grid>
                                                      <Grid
                                                         container
                                                         direction={"row"}
                                                         style={{
                                                            height: "30px",
                                                         }}
                                                         justify="space-between"
                                                      >
                                                         <Grid item xs={3}>
                                                            <Typography
                                                               variant="h6"
                                                               noWrap
                                                               style={{ color: "#010440", fontWeight: 700 }}
                                                            >
                                                               Address
                                                            </Typography>
                                                         </Grid>
                                                         <Grid item xs={3}>
                                                            <Typography
                                                               variant="h6"
                                                               noWrap
                                                               style={{ color: "#010440", fontWeight: 700 }}
                                                            >
                                                               City
                                                            </Typography>
                                                         </Grid>
                                                         <Grid item xs={2}>
                                                            <Typography
                                                               variant="h6"
                                                               noWrap
                                                               style={{ color: "#010440", fontWeight: 700 }}
                                                            >
                                                               State
                                                            </Typography>
                                                         </Grid>
                                                         <Grid item xs={3}>
                                                            <Typography
                                                               variant="h6"
                                                               noWrap
                                                               style={{ color: "#010440", fontWeight: 700 }}
                                                            >
                                                               Country
                                                            </Typography>
                                                         </Grid>
                                                      </Grid>
                                                      <Grid container direction={"row"} justify="space-between">
                                                         <Grid item xs={3}>
                                                            <Typography>{trade.address}</Typography>
                                                         </Grid>
                                                         <Grid item xs={3}>
                                                            <Typography>{trade.city}</Typography>
                                                         </Grid>
                                                         <Grid item xs={2}>
                                                            <Typography>{trade.state}</Typography>
                                                         </Grid>
                                                         <Grid item xs={3}>
                                                            <Typography>{trade.country}</Typography>
                                                         </Grid>
                                                      </Grid>
                                                   </Paper>
                                                </>
                                             );
                                          })}
                                       </CardContent>
                                    </Card>
                                 </Grid>
                              </Grid>
                           </div>
                        </div>
                     </div>
                  </>
               )}
            </div>
         </div>
         <NotesDialog
            open={notesModal}
            application={originalSelectedApplication}
            setApplication={setOriginalSelectedApplication}
            handleClose={closeNotesModal}
            onChange={onChangeNotes}
         />
         <ApprovalDialog
            open={approvalModal}
            application={selectedApplication}
            setApplication={setSelectedApplication}
            handleClose={() => setApprovalModal(!approvalModal)}
            onChange={onChange}
         />
         <DenialDialog
            open={denialModal}
            application={selectedApplication}
            setApplication={setSelectedApplication}
            handleClose={() => setDenialModal(!denialModal)}
            onChange={onChange}
            onSelectChange={onSelectChange}
            denialCodes={denialCodesList}
         />
         <SaveDialog
            title={"Change Email on Application"}
            text={changeEmailText}
            text2={"Are you sure you want to continue?"}
            open={saveModal}
            saveClicked={buttonClicked}
            handleSave={() => saveApplication(true)}
            handleClose={() => setSaveModal(!saveModal)}
         />
         <DeleteDialog
            title={"Delete Application"}
            text={deleteText}
            text2={"Are you sure you want to continue?"}
            open={deleteModal}
            handleDelete={() => deleteApplication()}
            handleClose={() => handleDeleteClose()}
         />
         <UploadDialog
            open={fileUploadModal}
            handleUpload={() => uploadTradeReferencesFile()}
            handleClose={() => setFileUploadModal(false)}
            title={`Upload ${fileUpload?.name}`}
            text={`Are you sure you want to upload the trade references? This will overwrite the current file.`}
         />
      </>
   );
};

export default ApplicationDetails;
