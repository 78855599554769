import { makeStyles, useTheme } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import CircularProgress from "@material-ui/core/CircularProgress";
import Collapse from "@material-ui/core/Collapse";
import { green } from "@material-ui/core/colors";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Slide from "@material-ui/core/Slide";
import { TransitionProps } from "@material-ui/core/transitions";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";
import Alert from "@material-ui/lab/Alert";
import _ from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { CompanyContext } from "../../stores/Companies/Context";
import { DivisionContext } from "../../stores/Divisions/Context";
import { UserContext } from "../../stores/Users/Context";
import { Companies } from "../../types/Companies";
import { Divisions } from "../../types/Divisions";
import * as CookieTools from "../../utilities/CookieTools";

const Transition = React.forwardRef(function Transition(
   props: TransitionProps & { children?: React.ReactElement<any, any> },
   ref: React.Ref<unknown>,
) {
   return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(() => ({
   label: {
      flexDirection: "column",
      fontSize: "11px",
   },
   saveButton: {
      color: "white",
      backgroundColor: green[500],
      "&:hover": {
         backgroundColor: green[600],
      },
   },
   buttonProgress: {
      color: green[500],
   },
}));

export const CompanyDialog: React.FC<any> = (props: { open: boolean; handleClose: () => void }): JSX.Element => {
   const classes = useStyles();
   const theme = useTheme();
   const mobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
   const [companyList, setCompanyList] = useState<Divisions[]>([]);
   const [selectedCompany, setSelectedCompany] = useState<Divisions>();
   const [buttonClicked, setButtonClicked] = useState<boolean>(false);
   const [alertMessage, setAlertMessage] = useState<string>("");
   const [alertType, setAlertType] = useState<"error" | "info" | "success" | "warning">("warning");
   const { state: companyState } = useContext(CompanyContext);
   const { state: divisionState } = useContext(DivisionContext);
   const { state: userState } = useContext(UserContext);

   //States Use Effect.
   useEffect(() => {
      //Merge these for a clean list.
      if (
         companyState.Companies.length > 0 &&
         divisionState.Divisions.length > 0 &&
         userState.CurrentUser._id !== "" &&
         companyList.length === 0
      ) {
         let newCompanyList: Divisions[] = [];

         //First, setup an all option.
         const newCompanyAll: Divisions[] = [
            {
               _id: "",
               company_id: "ALL",
               code: "ALL",
               name: "All Companies | Divisions",
               managers: [],
               active: true,
               prefix: "",
               counter: 0,
               addDate: null,
               deleteDate: null,
               invitationBody: "",
               invitationTopNote: "",
               emailTemplate: "",
               docuSignTemplateId: "",
               receiptFirstParagraph: "",
               receiptSecondParagraph: "",
               deniedFirstParagraph: "",
               deniedSecondParagraph: "",
               approvedFirstParagraph: "",
               approvedSecondParagraph: "",
               approvedBoldParagraph: "",
               contactApprovedBody: "",
               contactDeniedBody: "",
               duplicationNotification: "",
               contactDuplicationNotification: "",
               denialCodes: [],
               contacts: [],
            },
         ];

         newCompanyList = [...newCompanyList, ...newCompanyAll];

         if (userState.CurrentUser.security.toUpperCase() === "SUPER ADMIN") {
            companyState.Companies.forEach(function (company: Companies) {
               const divisionsAssigned = _.orderBy(
                  _.filter(divisionState.Divisions, (x) => x.company_id === company._id),
                  ["name"],
                  ["asc"],
               );

               if (divisionsAssigned.length > 0) {
                  const newCompany: Divisions[] = [
                     {
                        _id: "",
                        company_id: company._id,
                        code: company.code,
                        name: company.name,
                        managers: company.managers,
                        active: company.active,
                        prefix: company.prefix,
                        counter: company.counter,
                        addDate: company.addDate,
                        deleteDate: company.deleteDate,
                        invitationBody: company.invitationBody,
                        invitationTopNote: company.invitationTopNote,
                        emailTemplate: company.emailTemplate,
                        docuSignTemplateId: company.docuSignTemplateId,
                        receiptFirstParagraph: company.receiptFirstParagraph,
                        receiptSecondParagraph: company.receiptSecondParagraph,
                        deniedFirstParagraph: company.deniedFirstParagraph,
                        deniedSecondParagraph: company.deniedSecondParagraph,
                        approvedFirstParagraph: company.approvedFirstParagraph,
                        approvedSecondParagraph: company.approvedSecondParagraph,
                        approvedBoldParagraph: company.approvedBoldParagraph,
                        contactApprovedBody: company.contactApprovedBody,
                        contactDeniedBody: company.contactDeniedBody,
                        duplicationNotification: company.duplicationNotification,
                        contactDuplicationNotification: company.contactDuplicationNotification,
                        denialCodes: company.denialCodes,
                        contacts: company.contacts,
                     },
                  ];

                  newCompanyList = [...newCompanyList, ...newCompany];
                  newCompanyList = [...newCompanyList, ...divisionsAssigned];
               } else {
                  const newCompany: Divisions[] = [
                     {
                        _id: "",
                        company_id: company._id,
                        code: company.code,
                        name: company.name,
                        managers: company.managers,
                        active: company.active,
                        prefix: company.prefix,
                        counter: company.counter,
                        addDate: company.addDate,
                        deleteDate: company.deleteDate,
                        invitationBody: company.invitationBody,
                        invitationTopNote: company.invitationTopNote,
                        emailTemplate: company.emailTemplate,
                        docuSignTemplateId: company.docuSignTemplateId,
                        receiptFirstParagraph: company.receiptFirstParagraph,
                        receiptSecondParagraph: company.receiptSecondParagraph,
                        deniedFirstParagraph: company.deniedFirstParagraph,
                        deniedSecondParagraph: company.deniedSecondParagraph,
                        approvedFirstParagraph: company.approvedFirstParagraph,
                        approvedSecondParagraph: company.approvedSecondParagraph,
                        approvedBoldParagraph: company.approvedBoldParagraph,
                        contactApprovedBody: company.contactApprovedBody,
                        contactDeniedBody: company.contactDeniedBody,
                        duplicationNotification: company.duplicationNotification,
                        contactDuplicationNotification: company.contactDuplicationNotification,
                        denialCodes: company.denialCodes,
                        contacts: company.contacts,
                     },
                  ];

                  newCompanyList = [...newCompanyList, ...newCompany];
               }
            });
         } else {
            const assignedCompanies = _.orderBy(
               _.filter(companyState.Companies, (x) =>
                  userState.CurrentUser.companies ? userState.CurrentUser.companies.includes(x._id) : x._id === "",
               ),
               ["name"],
               ["asc"],
            );

            const assignedDivisions = _.orderBy(
               _.filter(divisionState.Divisions, (x) =>
                  userState.CurrentUser.divisions ? userState.CurrentUser.divisions.includes(x._id) : x._id === "",
               ),
               ["name"],
               ["asc"],
            );

            assignedCompanies.forEach(function (company: Companies) {
               const divisionsAssigned = _.orderBy(
                  _.filter(assignedDivisions, (x) => x.company_id === company._id),
                  ["name"],
                  ["asc"],
               );

               if (divisionsAssigned.length > 0) {
                  const newCompany: Divisions[] = [
                     {
                        _id: "",
                        company_id: company._id,
                        code: company.code,
                        name: company.name,
                        managers: company.managers,
                        active: company.active,
                        prefix: company.prefix,
                        counter: company.counter,
                        addDate: company.addDate,
                        deleteDate: company.deleteDate,
                        invitationBody: company.invitationBody,
                        invitationTopNote: company.invitationTopNote,
                        emailTemplate: company.emailTemplate,
                        docuSignTemplateId: company.docuSignTemplateId,
                        receiptFirstParagraph: company.receiptFirstParagraph,
                        receiptSecondParagraph: company.receiptSecondParagraph,
                        deniedFirstParagraph: company.deniedFirstParagraph,
                        deniedSecondParagraph: company.deniedSecondParagraph,
                        approvedFirstParagraph: company.approvedFirstParagraph,
                        approvedSecondParagraph: company.approvedSecondParagraph,
                        approvedBoldParagraph: company.approvedBoldParagraph,
                        contactApprovedBody: company.contactApprovedBody,
                        contactDeniedBody: company.contactDeniedBody,
                        duplicationNotification: company.duplicationNotification,
                        contactDuplicationNotification: company.contactDuplicationNotification,
                        denialCodes: company.denialCodes,
                        contacts: company.contacts,
                     },
                  ];

                  newCompanyList = [...newCompanyList, ...newCompany];
                  newCompanyList = [...newCompanyList, ...divisionsAssigned];
               } else {
                  const newCompany: Divisions[] = [
                     {
                        _id: "",
                        company_id: company._id,
                        code: company.code,
                        name: company.name,
                        managers: company.managers,
                        active: company.active,
                        prefix: company.prefix,
                        counter: company.counter,
                        addDate: company.addDate,
                        deleteDate: company.deleteDate,
                        invitationBody: company.invitationBody,
                        invitationTopNote: company.invitationTopNote,
                        emailTemplate: company.emailTemplate,
                        docuSignTemplateId: company.docuSignTemplateId,
                        receiptFirstParagraph: company.receiptFirstParagraph,
                        receiptSecondParagraph: company.receiptSecondParagraph,
                        deniedFirstParagraph: company.deniedFirstParagraph,
                        deniedSecondParagraph: company.deniedSecondParagraph,
                        approvedFirstParagraph: company.approvedFirstParagraph,
                        approvedSecondParagraph: company.approvedSecondParagraph,
                        approvedBoldParagraph: company.approvedBoldParagraph,
                        contactApprovedBody: company.contactApprovedBody,
                        contactDeniedBody: company.contactDeniedBody,
                        duplicationNotification: company.duplicationNotification,
                        contactDuplicationNotification: company.contactDuplicationNotification,
                        denialCodes: company.denialCodes,
                        contacts: company.contacts,
                     },
                  ];

                  newCompanyList = [...newCompanyList, ...newCompany];
               }
            });
         }

         setCompanyList(newCompanyList);
      }
   }, [companyState, divisionState, userState]);

   async function handleChange(
      event: React.ChangeEvent<{
         name?: string | undefined;
         value: unknown;
      }>,
   ) {
      if (event.target.value === "ALL") {
         setSelectedCompany({
            _id: "",
            company_id: "ALL",
            code: "ALL",
            name: "All Companies | Divisions",
            managers: [],
            active: true,
            prefix: "",
            counter: 0,
            addDate: null,
            deleteDate: null,
            invitationBody: "",
            invitationTopNote: "",
            emailTemplate: "",
            docuSignTemplateId: "",
            receiptFirstParagraph: "",
            receiptSecondParagraph: "",
            deniedFirstParagraph: "",
            deniedSecondParagraph: "",
            approvedFirstParagraph: "",
            approvedSecondParagraph: "",
            approvedBoldParagraph: "",
            contactApprovedBody: "",
            contactDeniedBody: "",
            duplicationNotification: "",
            contactDuplicationNotification: "",
            denialCodes: [],
            contacts: [],
         });
      } else {
         const selectedComp = _.filter(
            companyList,
            (x) => x.company_id === event.target.value || x._id === event.target.value,
         );

         if (selectedComp.length > 0) {
            setSelectedCompany(selectedComp[0]);
         } else {
            const selectedDivision = _.filter(companyList, (x) => x._id === event.target.value);

            if (selectedDivision.length > 0) {
               setSelectedCompany(selectedDivision[0]);
            }
         }
      }
   }

   async function saveApplication() {
      try {
         if (!selectedCompany) {
            openAlertMessage("You must select a company | division!", "warning");
            return;
         }

         if (!selectedCompany._id && !selectedCompany.company_id) {
            openAlertMessage("You must select a company | division!", "warning");
            return;
         }

         setButtonClicked(true);

         if (selectedCompany.company_id === "ALL") {
            let companySelectedList: string[] = [];
            let divisionSelectedList: string[] = [];

            _.forEach(companyList, async (company) => {
               if (company.company_id !== "ALL") {
                  companySelectedList.push(company.company_id);
               }

               if (company._id) {
                  divisionSelectedList.push(company._id);
               }
            });

            companySelectedList = _.uniq(companySelectedList);
            divisionSelectedList = _.uniq(divisionSelectedList);

            CookieTools.setCookie("selected-company", companySelectedList.toString());
            CookieTools.setCookie("selected-division", divisionSelectedList.toString());

            window.location.replace("/");
         } else {
            if (selectedCompany._id) {
               CookieTools.setCookie("selected-company", selectedCompany.company_id);
               CookieTools.setCookie("selected-division", selectedCompany._id);

               window.location.replace("/");
            } else {
               const divisionSelectedList: string[] = [];

               const divisionsAssigned = _.filter(
                  divisionState.Divisions,
                  (x) => x.company_id === selectedCompany.company_id,
               );

               _.forEach(divisionsAssigned, async (division) => {
                  await divisionSelectedList.push(division._id);
               });

               CookieTools.setCookie("selected-company", selectedCompany.company_id.toString());
               CookieTools.setCookie("selected-division", divisionSelectedList.toString());

               window.location.replace("/");
            }
         }

         setButtonClicked(false);
      } catch (err) {
         openAlertMessage("An error has occurred. Please reach out to administration for assistance.", "warning");
         setButtonClicked(false);
      }
   }

   async function openAlertMessage(alertMessage: string, alertType: "error" | "info" | "success" | "warning") {
      setAlertMessage(alertMessage);
      setAlertType(alertType);

      if (alertType === "success" || alertType === "warning") {
         setTimeout(function () {
            setAlertMessage("");
         }, 3000);
      }
   }

   return (
      <Dialog
         open={props.open || false}
         TransitionComponent={Transition}
         keepMounted
         fullScreen={mobileScreen}
         fullWidth
         maxWidth={"xs"}
      >
         <DialogTitle style={{ backgroundColor: "#002D72", color: "white", textAlign: "center" }}>
            Switch Company Account
         </DialogTitle>
         <DialogContent>
            <Collapse in={alertMessage ? true : false}>
               <Alert
                  variant="filled"
                  severity={alertType}
                  action={
                     <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                           setAlertMessage("");
                        }}
                     >
                        <CloseIcon />
                     </IconButton>
                  }
               >
                  {alertMessage}
               </Alert>
            </Collapse>
            <FormControl variant="outlined" style={{ width: "100%" }}>
               <Select id="select-companies" defaultValue="" onChange={(event) => handleChange(event)}>
                  {companyList.map((company) => {
                     return (
                        <MenuItem
                           style={{
                              fontWeight: company._id ? 500 : 700,
                              marginLeft: company._id ? "1rem" : "0rem",
                              color: company._id ? "#000000" : "#002D72",
                           }}
                           key={company._id ? company._id : company.company_id}
                           value={company._id ? company._id : company.company_id}
                        >
                           {(company._id ? "  " : "") + company.code + " | " + company.name}
                        </MenuItem>
                     );
                  })}
               </Select>
            </FormControl>
         </DialogContent>
         <DialogActions>
            <ButtonGroup>
               <Button
                  onClick={props.handleClose}
                  variant="contained"
                  classes={{
                     label: classes.label,
                  }}
               >
                  <CloseIcon />
                  Close
               </Button>
               <Button
                  onClick={saveApplication}
                  variant="contained"
                  color="primary"
                  classes={{
                     root: classes.saveButton,
                     label: classes.label,
                  }}
                  disabled={buttonClicked}
               >
                  {buttonClicked ? <CircularProgress size={20} className={classes.buttonProgress} /> : <SaveIcon />}
                  Switch
               </Button>
            </ButtonGroup>
         </DialogActions>
      </Dialog>
   );
};

export default CompanyDialog;
