const lowerCase = new RegExp(/[a-z]/);
const upperCase = new RegExp(/[A-Z]/);
const specialChar = new RegExp(/[!@#\$%\^\&*\)\(+=._-]/);
const minChar = new RegExp(/.{8,}/);
const minNum = new RegExp(/(?=.*?[0-9])/);
const emailValid = new RegExp(/^.+@(.+)[.](.+)$/);

export function validatePassword(password: string) {
   let validationMessage = "";

   if (!password.match(specialChar)) {
      validationMessage = "Password must contain at least one special character.";
   }
   if (!password.match(minNum)) {
      validationMessage = "Password must contain at least one number.";
   }
   if (!(password.match(lowerCase) && password.match(upperCase))) {
      validationMessage = "Password must contain a lowercase and an uppercase character.";
   }
   if (!password.match(minChar)) {
      validationMessage = "Password must be at least 8 characters in length.";
   }

   return validationMessage;
}

export function validateEmail(email: string) {
   let validationMessage = "";
   if (!email.match(emailValid)) {
      validationMessage = "Must be a valid email address.";
   }

   return validationMessage;
}
