import { CssBaseline } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import * as React from "react";
import ReactDOM from "react-dom";
import { ErrorBoundary } from "react-error-boundary";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import App from "./App";
import "./index.css";
import Apply from "./pages/Apply/Apply";
import DocuSignComplete from "./pages/Apply/DocuSign/DocuSignComplete";
import ApplyUWLWDS from "./pages/ApplyUWLWDS/ApplyUWLWDS";
import DocuSignCompleteUWL from "./pages/ApplyUWLWDS/DocuSign/DocuSignCompleteUWL";
import DocuSignCompleteWDS from "./pages/ApplyUWLWDS/DocuSign/DocuSignCompleteWDS";
import ErrorHandler from "./pages/ErrorHandler/ErrorHandler";
import Login from "./pages/Login/Login";
import ForgotPassword from "./pages/Password/ForgotPassword";
import PasswordReset from "./pages/Password/PasswordReset";
import Register from "./pages/Register/Register";
import reportWebVitals from "./reportWebVitals";
import { AppContextProvider } from "./stores/AppContextProvider";

const theme = createTheme({
   palette: {
      primary: {
         // light: will be calculated from palette.primary.main,
         main: "#002D72",
         // dark: will be calculated from palette.primary.main,
         // contrastText: will be calculated to contrast with palette.primary.main
      },
      background: {
         default: "#f2f2f2",
      },
      text: {
         primary: "#000000",
      },
   },
   typography: {
      fontFamily: `"Helvetica", sans-serif`,
      fontSize: 11,
   },
   overrides: {
      MuiInputBase: {
         input: {
            "&:-webkit-autofill": {
               //This is how we ignore the auto-filling styles from browsers like Chrome!!!!
               transitionDelay: "99999s",
               transitionProperty: "background-color, color",
            },
         },
      },
      MuiOutlinedInput: {
         root: {
            fontSize: "16px",
         },
         input: {
            padding: "16px 14px",
            height: 56,
            boxSizing: "border-box",
         },
      },
      MuiInputLabel: {
         root: {
            fontSize: "16px",
         },
         outlined: {
            transform: "translate(14px, 19px) scale(1)",
         },
      },
      MuiFormHelperText: {
         root: {
            fontSize: "16px",
         },
      },
   },
});

const themeMiddleBay = createTheme({
   palette: {
      primary: {
         // light: will be calculated from palette.primary.main,
         main: "#8F9864",
         // dark: will be calculated from palette.primary.main,
         // contrastText: will be calculated to contrast with palette.primary.main
      },
      background: {
         default: "#f2f2f2",
      },
      text: {
         primary: "#000000",
      },
   },
   typography: {
      fontFamily: `"Helvetica", sans-serif`,
      fontSize: 11,
   },
   overrides: {
      MuiInputBase: {
         input: {
            "&:-webkit-autofill": {
               //This is how we ignore the auto-filling styles from browsers like Chrome!!!!
               transitionDelay: "99999s",
               transitionProperty: "background-color, color",
            },
         },
      },
      MuiOutlinedInput: {
         root: {
            fontSize: "16px",
         },
         input: {
            padding: "16px 14px",
            height: 56,
            boxSizing: "border-box",
         },
      },
      MuiInputLabel: {
         root: {
            fontSize: "16px",
         },
         outlined: {
            transform: "translate(14px, 19px) scale(1)",
         },
      },
      MuiFormHelperText: {
         root: {
            fontSize: "16px",
         },
      },
   },
});

const themeBristol = createTheme({
   palette: {
      primary: {
         // light: will be calculated from palette.primary.main,
         main: "#1E376B",
         // dark: will be calculated from palette.primary.main,
         // contrastText: will be calculated to contrast with palette.primary.main
      },
      background: {
         default: "#f2f2f2",
      },
      text: {
         primary: "#000000",
      },
   },
   typography: {
      fontFamily: `"Helvetica", sans-serif`,
      fontSize: 11,
   },
   overrides: {
      MuiInputBase: {
         input: {
            "&:-webkit-autofill": {
               //This is how we ignore the auto-filling styles from browsers like Chrome!!!!
               transitionDelay: "99999s",
               transitionProperty: "background-color, color",
            },
         },
      },
      MuiOutlinedInput: {
         root: {
            fontSize: "16px",
         },
         input: {
            padding: "16px 14px",
            height: 56,
            boxSizing: "border-box",
         },
      },
      MuiInputLabel: {
         root: {
            fontSize: "16px",
         },
         outlined: {
            transform: "translate(14px, 19px) scale(1)",
         },
      },
      MuiFormHelperText: {
         root: {
            fontSize: "16px",
         },
      },
   },
});

const themeUWL_WDS = createTheme({
   palette: {
      primary: {
         // light: will be calculated from palette.primary.main,
         main: "#043F60",
         // dark: will be calculated from palette.primary.main,
         // contrastText: will be calculated to contrast with palette.primary.main
      },
      background: {
         default: "#f2f2f2",
      },
      text: {
         primary: "#000000",
      },
   },
   typography: {
      fontFamily: `"Helvetica", sans-serif`,
      fontSize: 11,
   },
   overrides: {
      MuiInputBase: {
         input: {
            "&:-webkit-autofill": {
               //This is how we ignore the auto-filling styles from browsers like Chrome!!!!
               transitionDelay: "99999s",
               transitionProperty: "background-color, color",
            },
         },
      },
      MuiOutlinedInput: {
         root: {
            fontSize: "16px",
         },
         input: {
            padding: "16px 14px",
            height: 56,
            boxSizing: "border-box",
         },
      },
      MuiInputLabel: {
         root: {
            fontSize: "16px",
         },
         outlined: {
            transform: "translate(14px, 19px) scale(1)",
         },
      },
      MuiFormHelperText: {
         root: {
            fontSize: "16px",
         },
      },
   },
});

const chooseThemes = (siteUrl: string) => {
   if (siteUrl.includes("/APPLY/BRISTOL")) return themeBristol;
   if (siteUrl.includes("/APPLY/MIDDLEBAY")) return themeMiddleBay;
   if (siteUrl.includes("/APPLY/UWL")) return themeUWL_WDS;
   if (siteUrl.includes("/APPLY/WDS")) return themeUWL_WDS;
   return theme;
};

const siteUrl = window.location.href;

const Routes = () => {
   React.useRef();
   return (
      <Router>
         <Switch>
            <Route path="/Login/:loggedOut?" exact component={Login} />
            <Route path="/DocuSignComplete/UWL/:envelopeID" exact component={DocuSignCompleteUWL} />
            <Route path="/DocuSignComplete/WDS/:envelopeID" exact component={DocuSignCompleteWDS} />
            <Route path="/DocuSignComplete/:division/:envelopeID" exact component={DocuSignComplete} />
            <Route path="/Apply/UWL/:email/:applicationID" exact component={ApplyUWLWDS} />
            <Route path="/Apply/WDS/:email/:applicationID" exact component={ApplyUWLWDS} />
            <Route path="/Apply/:division/:email/:applicationID" exact component={Apply} />
            <Route path="/Register/:_id/:token" exact component={Register} />
            <Route path="/ForgotPassword" exact component={ForgotPassword} />
            <Route path="/PasswordReset/:_id?/:invite?" exact component={PasswordReset} />
            <Route path="/" component={App} />
         </Switch>
      </Router>
   );
};

ReactDOM.render(
   <ErrorBoundary FallbackComponent={ErrorHandler}>
      <AppContextProvider>
         <ThemeProvider theme={chooseThemes(siteUrl.toUpperCase())}>
            <CssBaseline />
            <Routes />
         </ThemeProvider>
      </AppContextProvider>
   </ErrorBoundary>,

   document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
